HomesCtrl = ($scope
, $state
, $log
, $ionicPopup
, SurveyResponse
, Util
, responses
, Maps
, googleReady
)->
  $log.log 'homes controller'

  $scope.list = [
    {title:'one',val:1},
    {title:'two',val:2},
    {title:'three',val:3}
  ]

  $scope.click = (r)->
    $state.go 'canvass.surveyResponseid', {id:r._id || r.tmpId}

  $scope.goToHome = (r, e)->
    e.preventDefault()
    e.stopImmediatePropagation()
    $state.go 'canvass.home'
    pos = r.position
    Maps.goToPosition pos
    Maps.setZoom 18

  $scope.responses = responses

  $scope.classFromStatus = (r)->
    'bg' + Util.colorFromStatus(r)

  $scope.total = SurveyResponse.total()
  $scope.leads = SurveyResponse.leads()
  $scope.presented = SurveyResponse.presented() - $scope.leads
  $scope.notAvailable = $scope.total - $scope.leads - $scope.presented

  $scope.sync = ()->
    SurveyResponse.sync()
    .then (responses)->
      popup = null
      if responses
        popup = $ionicPopup.alert(
          title: 'Success!'
          template: 'Your survey data is now synced with the server.  \
          You may still need to <a href="#/myLeads"> \
          confirm your leads</a> at the end of the day.'
        )
      else
        popup = $ionicPopup.alert(
          title: 'Unable to Sync!'
          template: 'We were unable to sync your data to the server, \
          likely because you are not online at the moment. \
          Please check your internet connection and try again.'
        )

      popup.then ()->
        # refresh the list, if we're online
        $log.log 'reload responses list'
        SurveyResponse.cachedTimestamp = 0
        $state.go $state.current, {}, {reload:true}

    .catch (err)->
      $log.error 'unable to sync', err

HomesCtrl.resolve =
  googleReady: (Util)->
    Util.waitForGoogle()
  responses : (SurveyResponse, $log)->
    try
      return SurveyResponse.getTodaysResponses()
    catch e
      $log.error 'error resolve homes', e

    return []
app.controller 'HomesCtrl', HomesCtrl
