'use strict'
app.factory 'Messages', (
  $rootScope
, $log
, Socket
, Now
, Util

) ->

  if !Socket
    return null

  service = {}
  service.channels = []

  getChannel = (id)->
    service.channels.find (c)->
      c._id == id

  Socket.on 'users', (data)->
    $log.log 'users', data
    c = getChannel data.channel
    usernames = data.users.map (u)->
      u.name
    
    c?.messages.push
      timestamp: Now()
      text: '<br/>' + usernames.join '<br/>'
      user:
        name: 'Connected users'

  Socket.on 'message', (data)->
    $log.log 'message', data
    channel = getChannel data.channel
    return if !channel
    channel.messages.push data
    service.isDirty = true
    channel.dirtyIndicator = "*"

  Socket.on 'join', (data)->
    $log.log 'join', data
    data.text = "[joined]"
    data.timestamp = Now()
    channel = getChannel data.channel
    channel?.messages.push data

  Socket.on 'leave', (data)->
    $log.log 'leave', data
    data.text = "[left]"
    data.timestamp = Now()
    c = getChannel data.channel
    c?.messages.push data

  service.leaveAll = ()->
    $log.log 'leaveall'
    if $rootScope.currentUser
      service.channels.forEach (c)->
        Socket.emit 'leave',
          channel: c._id
          user: $rootScope.currentUser
      service.channels = []

  joinCustomer = (c, user)->
    return if !c.enableChat

    service.join [service.makeChannel c], user
      
    if c.users
      findCurrentUser = c.users.find (u)->
        u._id == user._id
      # don't join direct chat rooms
      # if we are not already listed
      # as a member of the company
      # this is because the other user
      # will not be able to join the room
      # And, they probably won't know know
      # who you are (one of the admins)
      return if !findCurrentUser
    
      directs = c.users.map (u)->
        return null if u._id == user._id
        service.makeChannel [u,user]

      # filter out nulls. the map function can't skip values
      directs = directs.filter (c)->
        c
         
      # this will check for dupes
      service.join directs, user


  service.setCustomer = (c, customers)->
      
    if !c
      service.leaveAll()
      return
    
    user =
      _id: $rootScope.currentUser._id
      name: $rootScope.currentUser.name

    joinCustomer c, user


    # join all the rest
    
    customers.forEach (c)->
      joinCustomer c, user

    return



  service.join = (channels, user)->
    # check for existing..
    filtered = channels.filter (test)->
      found = service.channels.find (c)->
        c._id == test._id
      !found

    filtered.forEach (channel)->
      if !channel.joined
        $log.log 'joining channel', channel
        Socket.emit 'join',
          channel: channel._id
          user: user
        channel.joined = true
        channel.messages = []
        channel.sendMessage = ()->
          return if !channel.message?.trim().length
          message =
            channel: channel._id
            user: user
            text: channel.message
            timestamp: Now()

          Socket.emit 'message', message

          channel.messages.push message
          channel.message = ''

        channel.requestUsers = ()->
          $log.log 'requestusers'
          Socket.emit 'users',
            channel: channel._id

        service.channels.push channel

  service.makeChannel = (from)->
    if Array.isArray from
      # sort by ids.  ensures that channels are named identically
      # regardless of order of members in it
      sorted = from.sort (a,b)->
        if a._id > b._id
          return 1
        if a.id_ < b._id
          return -1
        return 0

      keys = sorted.map (i)->
        i._id

      others = sorted.filter (o)->
        o._id != $rootScope.currentUser._id

      separator = ' & '
      names = others.reduce (memo, i)->
        memo + separator + i.name
      , ''

      names = names.substring separator.length
      key = JSON.stringify keys
      
      channel =
        _id: key
        name: names

    else
      channel =
        _id: from._id
        name: from.name
        users: from.users

    return channel

  return service
