'use strict'
app.factory 'Api', ($q,
  $http,
  $log,
  Config
) ->
  (endpoint)->
    defaultConfig =
      timeout: 5000
    baseurl = "#{Config.serverUri}/api/#{endpoint}"
    convertToIds = (obj, fields)->
      return if ! fields
      fields.forEach (field)->
        if obj[field]?.forEach
          #an array of objects or strings
          obj[field].forEach (item, index)->
            # if it's an object, it will have an _id field
            # otherwise it's a string id already
            if item._id
              obj[field][index] = item._id
        else
          #a single object or string
          #if it's a string, just leave it alone
          if obj[field]?._id
            obj[field] = obj[field]._id

    all: ()->
      deferred = $q.defer()
      try
        $http.get(baseurl, defaultConfig)
        .success (data)->
          deferred.resolve data
        .error (data, status, headers, config)->
          deferred.reject
            data: data
            status: status
            headers: headers
            config : config

      catch ex
        deferred.reject ex

      return deferred.promise

    some: (params, populateFields, options)->
      deferred = $q.defer()
      try

        # query
        param = '?q=' + JSON.stringify(params)

        #populateFields
        if populateFields
          param += '&p=' + populateFields

        if options
          param += '&o=' + JSON.stringify(options)

        $http.get(baseurl + param, defaultConfig)
        .success (data)->
          deferred.resolve data
        .error (data, status, headers, config)->
          deferred.reject
            data: data
            status: status
            headers: headers
            config : config

      catch ex
        deferred.reject ex

      return deferred.promise


    one: (id)->
      deferred = $q.defer()
      try

        $http.get("#{baseurl}/#{id}", defaultConfig)
        .success (data)->
          deferred.resolve data
        .error (data, status, headers, config)->
          deferred.reject
            data: data
            status: status
            headers: headers
            config : config

      catch ex
        deferred.reject ex

      return deferred.promise


    save: (item, fieldsToConvertToIds, populateFields)->

      
      deferred = $q.defer()
      try

        #copy in order to not polute the existing item
        copy = angular.copy item
        convertToIds copy, fieldsToConvertToIds

        #populateFields
        if (typeof populateFields) == 'string'
          copy.populate = populateFields

        #$log.log 'copy', copy
        $http.put(baseurl, copy, defaultConfig)
        .success (item)->
          deferred.resolve item
        .error (data, status, headers, config)->
          deferred.reject
            data: data
            status: status
            headers: headers
            config : config
      catch ex
        deferred.reject ex

      return deferred.promise

    delete: (id)->
      deferred = $q.defer()
      try

        $http.delete("#{baseurl}/#{id}", defaultConfig)
        .success (data)->
          deferred.resolve data
        .error (data, status, headers, config)->
          deferred.reject
            data: data
            status: status
            headers: headers
            config : config
      catch ex
        deferred.reject ex

      return deferred.promise
