TeamsCtrl = ($scope
  , $state
  , $log
  , teamInfo

)->
   
  $log.log 'teamssctrl'
  [teams, currentTeam, Customer] = teamInfo

  $scope.teams = teams
  $scope.currentTeam = currentTeam
  $scope.selectTeam = (team)->
    $scope.currentTeam = team
    Customer.setCurrentCustomer(team)
    

TeamsCtrl.resolve =
  teamInfo: (Customer, $log)->
    try
      return Customer.getCurrentCustomer().then (customer)->
        [Customer.currentCustomers, customer, Customer]
    catch e
      $log.error 'error resolve teams', e
    return []

app.controller 'TeamsCtrl', TeamsCtrl
