app.controller 'RouteCtrl', ($scope
, $rootScope
, Route
)->
  $scope.updateRoutes = (route)->

    # only allow one active route at a time
    Route.myRoutes.forEach (r)->
      if r._id == route._id
        r.active = route.active
      else
        r.active = false

    Route.saveMyRoutes()
