'use strict'
app.factory 'Logger', ($injector) ->
  # get the line numbers right? ...
  
  ln = ->
    e = new Error
    if !e.stack
      try
        # IE requires the Error to actually be throw or else the Error's 'stack'
        # property is undefined.
        throw e
      catch e
        if !e.stack
          return 0
          # IE < 10, likely
    stack = e.stack.toString().split(/\r\n|\n/)
    # We want our caller's frame. It's index into |stack| depends on the
    # browser and browser version, so we need to search for the second frame:
    frameRE = /:(\d+):(?:\d+)[^\d]*$/
    loop
      frame = stack.shift()
      unless !frameRE.exec(frame) and stack.length
        break
    result = stack[1]
    result

  ($delegate)->

    warn: ()->
      $rootScope = $injector.get('$rootScope')

      if arguments.length == 1
        args = arguments[0]
      else
        args = [].slice.call(arguments)

      lineinfo = ln()
      console.warn args, lineinfo

      if !(Array.isArray args)
        args = [args]

      args.unshift $rootScope.currentUser?.email
      args.push lineinfo
      console.warn.apply(console, args)

    info: ()->
      $rootScope = $injector.get('$rootScope')
      if arguments.length == 1
        args = arguments[0]
      else
        args = [].slice.call(arguments)

      lineinfo = ln()
      console.info args, lineinfo

      if !(Array.isArray args)
        args = [args]

      args.unshift $rootScope.currentUser?.email
      args.push lineinfo
      console.info.apply(console, args)

    error: ()->
      $rootScope = $injector.get('$rootScope')
      if arguments.length == 1
        args = arguments[0]
      else
        args = [].slice.call(arguments)

      lineinfo = ln()
      console.error args, lineinfo

      if !(Array.isArray args)
        args = [args]
      
      args.unshift $rootScope.currentUser?.email
      args.push arguments[0].stack
      args.push lineinfo
      console.error.apply(console, args)

    log: ()->

      # don't log unless debug turned on
      return if !this.debugMode

      $rootScope = $injector.get('$rootScope')
      if arguments.length == 1
        args = arguments[0]
      else
        args = [].slice.call(arguments)

      lineinfo = ln()
      console.log args, lineinfo

      if !(Array.isArray args)
        args = [args]

      args.push lineinfo
      args.unshift $rootScope.currentUser?.email
      console.log.apply(console, args)

