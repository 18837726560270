
BodyCtrl = ($scope
, Survey
, Customer
)->

  $scope.Survey = Survey
  $scope.Customer = Customer


app.controller 'BodyCtrl', BodyCtrl
