EstimatesCtrl = ($scope
, $rootScope
, $state
, home
)->
  
  $scope.home = home
  if ! home.estimates
    home.estimates = []

  $scope.newEstimate = ()->
    now = new Date()
    home.estimates.push
      title: ''
      dateAdded: now
      dateUpdated: now

    params =
      home: home._id
      index: home.estimates.length-1

    $state.go 'canvass.estimate', params

  return

EstimatesCtrl.resolve =
  home: (SurveyResponse, $stateParams)->
    SurveyResponse.one($stateParams.home)

app.controller 'EstimatesCtrl', EstimatesCtrl
