'use strict'

app.factory 'Mail', (
  $http
  , $q
  ,Config
) ->

  send: (msg)->
    deferred = $q.defer()
    $http.put("#{Config.serverUri}/api/mail/send", msg)
    .success( (data, status, headers, config)->
      if !data || !data[0] || data[0].status != "sent"
        deferred.reject {
          data: data
          status: status
          headers: headers
          config: config
        }
      else
        deferred.resolve data
    ).error (data, status, headers, config)->
      deferred.reject 'unexpected error'


    deferred.promise
