'use strict'

# coffeelint: disable=max_line_length
getHomeKey = (hm)->
  key = ''
  address = hm.questions.lead.addressDetail
  if address
    key = "#{address.streetNumber} #{address.streetName}, #{
      address.city}, #{address.state} #{
        address.zip}, #{address.country}"
  else
    # this should group homes approximately 35' nearby
    lat = hm.position.coords.latitude.toFixed(4)
    lng = hm.position.coords.longitude.toFixed(4)
    key = "#{lat},#{lng}"

  key

app.factory "SurveyResponse", ($q
  , $rootScope
  , $log
  , $timeout
  , Auth
  , Api
  , Online
  , LocalStorage
  , Guid
  , Now
  , Survey
  , Route
) ->
  service = Api('surveyresponse')

  service.allHomesByAddress = {}

  service.sortMyResponses = ()->
    # sorts in-place
    service.myResponses.sort (a, b)->
      if a.dateUpdated < b.dateUpdated
        return -1
      if a.dateUpdated > b.dateUpdated
        return 1
      return 0


  persistResponsesToLocalStorage = ()->
    $log.log 'persisting responses'
    # don't bother storing survey and canvasser data
    # redundantly - takes up too much storage
    # the system will pull rehydrate from current info

    responses = angular.copy service.myResponses

    responses.forEach (r)->
      fieldsToIds r

    LocalStorage.myResponses = angular.toJson responses

  retreiveResponsesFromLocalStorage = ()->
    $log.log 'hydrating responses'
    rs = LocalStorage.myResponses
    if rs
      try
        rs = JSON.parse(rs)
      catch e
        $log.error 'unable to parse myResponses', e
        rs = []
    else
      rs = []

    # handle case where localstorage = "null"
    # && JSON.parse("null") == null
    if !rs
      rs = []

    # ensure datetimes and surveys are ok
    rs.forEach ensureFields

    # sanity check, and filter for today only
    rs = rs.filter (r)->
      r && r.dateUpdated > today

    [homes, byAddress] = responsesToHomes rs
    service.myResponses = homes
    service.sortMyResponses()
    return homes

  ensureFields = (r)->
    return if !r or !r.dateUpdated
    if toString.call(r.dateUpdated) != '[object Date]'
      r.dateUpdated = new Date(r.dateUpdated)
      r.dateAdded = new Date(r.dateAdded)

    # trim all history records to length of 10. the history
    # is really only used to display the previous visits.
    # the database actually has a record for each visit anyway.
    if r.history?.length > 10
      r.history.length = 10

    # we don't need to access canvasser directly
    # so no need to rehydrate the canvasser

    if r.survey && !r.survey._id
      #get the local copy
      survey = Survey.getById r.survey
      if survey
        r.survey = survey
      else
        if Survey.currentSurvey
          r.survey = Survey.currentSurvey
        else
          # don't know what survey this is...
          $log.log 'unknown survey', r.survey
          # not sure what to do.
          # possibly only a case with old leads

  fieldsToIds = (r)->
    if r.canvasser?._id
      r.canvasser = r.canvasser._id
    if r.survey?._id
      r.survey = r.survey._id

  today = Now()
  today.setHours 0,0,0,0
  todayTime = today.getTime()


  service.reset = ()->
    $log.log 'clearing responses'
    unsynced = service.myResponses.filter (r)->
      r.queueStatus
    if unsynced.length
      # don't trash unsynced items
      # todo: check this in the logout process first


    else
      service.myResponses = []
      persistResponsesToLocalStorage()

  $rootScope.$on 'logout', service.reset

  apiOne = service.one
  service.one = (id)->

    #$log.log 'searching for id', id
    cached = service.findHome id
    if cached.home

      # I was finding an angular rendering issue
      # if I resolved the promise and immediately returned it.
      # The response form was put into an invalid state so that it was
      # reset (blank).  Unclear what part of the form processing logic
      # was being effected.
      # But this timeout allows the response to be handled in more-or-less
      # exactly the same rendering cycle as one returned by the http repsonse
      return new Promise (resolve, reject)->

        $timeout ()->
          ensureFields cached.home
          resolve cached.home
        , 0


    # fallback to direct query
    return apiOne(id).then (home)->
      updateCache home
      home

  updateCache = (home)->
    if !home.key
      home.key = getHomeKey home
    service.allHomesByAddress[home.key] = home

  service.updateCache = updateCache

  filterForCurrentSurvey = (rs)->
    # don't filter if we don't have current survey yet.
    # should only happen if refreshing the page on the responses page
    if !Survey.currentSurvey
      return rs
    filtered = rs.filter (r)->
      # if we're offline, the new responses will not have a full survey object
      # the survey field will be the survey id
      if r.survey._id
        return r.survey._id == Survey.currentSurvey._id
      else
        return r.survey == Survey.currentSurvey._id

    filtered


  service.allHomesInArea = {}
  # todo: caching and cache expiry
  service.getResponsesInArea = (bounds)->
    deferred = $q.defer()

    if !Auth.isLoggedIn()
      deferred.resolve []
      return deferred.promise

    return Online.confirmedState().then (state)->

      # offline
      if ! ( (state == 'up') && Survey.currentSurvey )
        if !service.myResponses?.length
          retreiveResponsesFromLocalStorage()

        return service.myResponses

      # online

      ne = bounds.getNorthEast()
      sw = bounds.getSouthWest()
      len = 7 # trim keeps request shorter
      area =
        maxLat: ne.lat().toFixed(len)
        minLat: sw.lat().toFixed(len)
        maxLng: ne.lng().toFixed(len)
        minLng: sw.lng().toFixed(len)

      # no longer remove homes we know about.
      # as we're only requesting one tile at at time.

      # # remove items with - in them (unsynced homes)
      # existingHomeIds = Object.keys(service.allHomesInArea).filter (h)->
      #   !(/-/.test h._id)

      # console.log 'existing ids', existingHomeIds
      # # don't submit more than 300 existing homes.
      # existingHomeIds = existingHomeIds.slice(0,250)

      params =
        # $or: [
        {
          'position.coords.latitude':
            $lt: area.maxLat
            $gt: area.minLat
          'position.coords.longitude':
            $lt: area.maxLng
            $gt: area.minLng
          survey:
            $in: [Survey.currentSurvey._id]
        }
        #   # ,{
        #   #   $and:[
        #   #     canvasser: $rootScope.currentUser._id
        #   #     dateUpdated:
        #   #       $gt: today
        #   #   ]
        #   # }
        # ]

        # _id:
        #   $nin: existingHomeIds

      today = Now()
      today.setHours 0,0,0,0

      #console.log 'params', params
      return service.some(params).then (results)->
        #console.log 'results length', results.length
        #console.log 'results', results
        # eliminate null entries?
        results = results.filter (r)->
          r

        results.forEach ensureFields

        # add new homes
        results.forEach (h)->
          service.allHomesInArea[h._id] = h

        # now, remove homes not in this area
        # from previous requests
        # we could add a buffer zone here?
        # for k,h of service.allHomesInArea
        #   if h.position.coords.latitude < area.minLat
        #     delete service.allHomesInArea[k]
        #   if h.position.coords.latitude > area.maxLat
        #     delete service.allHomesInArea[k]
        #   if h.position.coords.longitude < area.minLng
        #     delete service.allHomesInArea[k]
        #   if h.position.coords.longitude > area.maxLng
        #     delete service.allHomesInArea[k]

        allHomes = []
        myHomesToday = []
        for k,h of service.allHomesInArea
          allHomes.push h

          if (h.canvasser._id == $rootScope.currentUser._id) &&
          (h.dateUpdated > today)

            myHomesToday.push h

        #console.log('myHomesToday', myHomesToday)
        mergeResponses myHomesToday

        persistResponsesToLocalStorage()
        service.cachedTimestamp = Now().getTime()
        return allHomes

      .catch (err)->
        console.log 'error getting surveyresponses', err
        # reset
        service.allHomesInArea = {}
        []

  service.getTodaysResponses = ()->
    deferred = $q.defer()

    if !Auth.isLoggedIn()
      deferred.resolve []
      return deferred.promise

    cacheExpiry = 1 * 60 * 60 * 1000 # 1 hour
    now = Now().getTime()
    service.cachedTimestamp = service.cachedTimestamp || 0
    expirytime = service.cachedTimestamp + cacheExpiry
    cacheExpired = now > expirytime
    #$log.log 'cache', cacheExpiry, now, expirytime, cacheExpired
    if !cacheExpired
      deferred.resolve service.myResponses
      return deferred.promise

    Online.confirmedState().then (state)->

      if state != 'up'
        # offline
        if !service.myResponses?.length
          retreiveResponsesFromLocalStorage()

        deferred.resolve service.myResponses
        return deferred.promise

      # online

      today = Now()
      today.setHours 0,0,0,0

      params =
        canvasser: $rootScope.currentUser._id
        dateUpdated:
          $gt: today

      service.some(params).then (responses)->
        if !responses?.length
          responses = []

        [homes, byAddress] = responsesToHomes responses

        homes.forEach ensureFields

        service.myResponses = homes
        deferred.resolve service.myResponses
        persistResponsesToLocalStorage()
        service.cachedTimestamp = Now().getTime()

    deferred.promise


  apisave = service.save
  service.save = (response)->

    deferred = $q.defer()
    #$log.log 'to save', response
    if !response
      deferred.reject 'invalid response (null/undefined)'
      return deferred.promise

    Online.confirmedState()
    .then (state)->

      # see if this is an edit or not
      if response._id
        if !response.queueStatus # I could be editing an unsynced item
          response.queueStatus = 'update'

        response.dateUpdated = Now()

        found = service.findHome response._id

        if found.home
          # for some reason the response we have
          # is not a reference to the one in the
          # list, so we need to find it and
          # replace it.
          #
          found.list[found.index] = response

        if found.list != service.myResponses
          # just add it to the myResponses list for future syncing
          service.myResponses.unshift response
          service.sortMyResponses()

      else
        response.queueStatus = 'insert'
        response._id = Guid()
        response.dateAdded = Now()
        response.dateUpdated = response.dateAdded
        service.myResponses.unshift response
        # shouldn't be needed, but to be safe...
        service.sortMyResponses()

        # ensure allHomesByAddress has correct reference
        updateCache response

        # todo remove references to allHomesInArea
        service.allHomesInArea[response._id] = response


      if !response.key
        response.key = getHomeKey response

      #console.log 'broadcasting homeSaved'
      $rootScope.$broadcast 'homeSaved', response

      deferred.resolve response

      # myresponses will sync to server if/when we are online
      sync(state)

    deferred.promise

  service.cacheHomes = (byAddress)->

    # this just overwrites the cache
    for k, h of byAddress
      service.allHomesByAddress[k] = h

  service.findHome = (id)->
    #myresponses
    #allhomesInArea
    #myleads


    foundHome = undefined
    foundIndex = undefined

    mapHomes = Object.keys(service.allHomesByAddress).map (k)->
      service.allHomesByAddress[k]

    list = [
      mapHomes,
      service.myResponses,
      service.myLeads,
    ].find (list, index)->

      return if !list

      foundHome = list.find (home, idx)->
        if !home
          console.error 'invalid home', list, idx
          return false
        return id == (home._id || home.tmpId)


      if foundHome
        foundIndex = index
      # foundHome == undefined if not yet found, so it will move to next list
      return foundHome

    home: foundHome
    index: foundIndex
    list: list


  # returns a promise for a saved response
  # or the unchanged response if onffline
  syncOne = (r, index, array)->

    deferred = $q.defer()

    if !r.queueStatus
      deferred.resolve r
      return deferred.promise

    if r.queueStatus == 'insert'
      r.tmpId = r._id
      delete r._id

    else

      if r.queueStatus != 'update'
        deferred.reject 'invalid queue status'
        return deferred.promise

    #if for some reason we do not have an id, this will need to be an insert
    if !r._id
      r.queueStatus == 'insert'

    r.tmpStatus = r.queueStatus
    delete r.queueStatus

    $log.log 'about to save response/home to server', r
    apisave(r, ['canvasser','survey', 'assigned'], '')
    .catch( (err)->
      $log.error 'problem saving the survey response', err, r
      # reset the item
      r._id = r.tmpId
      delete r.tmpId
      r.queueStatus = r.tmpStatus
      delete r.tmpStatus

      deferred.reject err

    ).then (saved)->
      # replace the old with the new
      #$log.log 'succesfully saved!'
      #$log.log 'saved response', saved
      ensureFields saved
      if service.allHomesInArea[r.tmpId]
        delete service.allHomesInArea[r.tmpId]
      $rootScope.$broadcast 'surveyResponseUpdated',
        tmpId: r.tmpId
        home: saved

      # update the route with new ids if needed

      # Route.updateHome r.tmpId, saved

      deferred.resolve saved

    return deferred.promise

  syncall = (state)->
    deferred = $q.defer()
    if state != 'up'
      return deferred.reject 'not online'


    promises = []
    service.myResponses.forEach (r, index, array)->

      promise = syncOne(r)
      .then (saved)->
        if saved
          array[index]=saved
        else
          $log.log 'invalid saved item', saved
        return saved
      .catch (err)->
        $log.error 'error saving response', err, r

      promises.push promise

    $q.all(promises).then (responses)->

      persistResponsesToLocalStorage()
      deferred.resolve responses
    .catch (err)->
      # save, because items may have been modified
      # during sync process (some may have been successful)
      persistResponsesToLocalStorage()
      deferred.reject err

    return deferred.promise


  service.lastsync = 0
  sync = (online)->
    # if we already know we're online
    # (because this is triggered from an
    # 'up' event)
    # then we don't need to confirm the
    # online state.
    if online
      # having trouble with the 'confirmed-up' event firing
      # multiple times (other services doing a 'check' trigger)
      # let's only sync if it's been a while since the last time.
      # we don't get online/offline events that often...
      now = Now().getTime()

      timeSinceLastSync = now-service.lastsync
      #$log.log 'timeSinceLastSync', timeSinceLastSync
      if timeSinceLastSync > 2000
        service.lastsync = now
        #$log.log 'syncing!'
        return syncall('up')
      else
        return true
    else
      return  Online.confirmedState().then syncall


  service.countProperty = (rs, name)->
    rs.reduce (memo, r)->
      return memo if !r
      #$log.log r.canvasser.name, r.dateAdded
      if r.questions?[name]
        return memo + 1
      else
        return memo
    , 0



  filterForToday = ()->
    # this is inefficient to call several times in a row
    # caching??
    service.myResponses.filter (r)->
      r.dateUpdated.getTime() >= todayTime

  service.total = ()->

    todays = filterForToday()
    todays.length

  service.presented = ()->

    todays = filterForToday()
    service.countProperty todays, 'doorAnswered'

  service.leads = ()->
    todays = filterForToday()
    service.countProperty todays, 'gotLead'

  mergeResponses = (responses)->

    responses.forEach (nr)->
      foundIndex = service.myResponses.findIndex (er)->
        nr._id == er._id
      if foundIndex > -1
        # replace response with the new one
        service.myResponses[foundIndex] = nr
      else
        service.myResponses.unshift nr

    service.sortMyResponses()

  service.getMyLeads = ()->
    deferred = $q.defer()

    if !Auth.isLoggedIn()
      deferred.resolve []
      return deferred.promise

    Online.confirmedState().then (state)->
      if state != 'up'

        if service.myLeads
          deferred.resolve service.myLeads
        else
          if LocalStorage.myLeads

            service.myLeads = JSON.parse(LocalStorage.myLeads)
            [homes, byAddresss] = responsesToHomes service.mayLeads
            service.myLeads = homes
            deferred.resolve service.myLeads

          else
            service.myLeads = []
            deferred.resolve service.myLeads
        return deferred.promise

      currentUser = Auth.currentUser()

      params =
        canvasser: currentUser._id
        'questions.gotLead': true

      service.some(params)
      .catch (err)->
        $log.error 'error surveyResponse.some', err
        deferred.reject err
      .then (responses)->

        # check in case responses is invalid for some reason...
        if responses?.length
          #ensure date fields are date objects
          responses.forEach ensureFields

          [homes, byAddress] = responsesToHomes responses

          service.myLeads = homes
          LocalStorage.myLeads = angular.toJson service.myLeads

        deferred.resolve homes

    deferred.promise

  service.sync = sync

  service.getPrev = (resp)->
    if !service.myResponses.length
      return undefined

    thisIndex = service.myResponses.findIndex (r)->
      if resp.tmpId
        return r.tmpId == resp.tmpId
      else
        return r._id == resp._id

    prevIndex = thisIndex - 1

    if prevIndex < 0
      prevIndex = 0

    prev = service.myResponses[prevIndex]
    return prev

  service.getNext = (resp)->
    if !service.myResponses.length
      return undefined

    thisIndex = service.myResponses.findIndex (r)->
      if resp.tmpId
        return r.tmpId == resp.tmpId
      else
        return r._id == resp._id

    # if thisIndex == -1 (not found)
    # the next operation will set the index to 0 (beginning of the list)
    # which is fine.

    nextIndex = thisIndex + 1

    if nextIndex >= service.myResponses.length
      nextIndex = service.myResponses.length - 1

    next = service.myResponses[nextIndex]
    return next

  getResponsesFromResponse = (response)->
    history = response.history
    result = [response]
    if history?.length
      delete response.history
      children = history.map(getResponsesFromResponse).flat()
      result = [response, children...].flat()

    return result

  addResponseToHistory = (newerResponse, olderResponse)->
    if !newerResponse
      return olderResponse

    if !newerResponse.history
      newerResponse.history = []
    # the existing home will be newer than the olderResponse
    maxLen = 10
    if newerResponse.history?.length >= maxLen
      # drop this older response on the floor
      console.log 'skipping old response', olderResponse
      return newerResponse

    newerResponse.history.push olderResponse

    newerResponse


  # combines homes with same address
  responsesToHomes = (responses)->

    # extract all of the records from the history fields
    # every response will no longer have a history
    allResponses = responses.map(getResponsesFromResponse).flat()

    uniqueResponseUpdates = {}
    # ensure date field
    allResponses.forEach (r)->
      if (typeof r.dateUpdated) == 'string'
        r.dateUpdated = new Date(r.dateUpdated)
      if (typeof r.dateAdded) == 'string'
        r.dateAdded = new Date(r.dateAdded)

    # sort from most recent to oldest
    sorted = allResponses.sort (a, b)->
      return b.dateUpdated - a.dateUpdated

    # traverse the list in oldest to most recent
    # assigning by id + dateUpdated to remove duplicates.
    # most-recent wins
    sorted.reverse().forEach (r)->
      #just a handy place to do this
      if !r.key
        r.key = getHomeKey r

      # id added to avoid collisions between different updates by different users
      # extremely unlikely but should be safe. We definitely will have duplicates of these
      # due to the way we store history records in each new visit record.
      # they are actually not expected to be different records, just pure duplicates.
      key = r._id + '-' + r.dateUpdated.getTime()
      uniqueResponseUpdates[key] = r

    # Object.keys are not guaranteed to be in order, so needs re-sorting
    filtered = Object.keys(uniqueResponseUpdates).map (k)-> uniqueResponseUpdates[k]

    # re-sort from most recent to oldest
    sorted = filtered.sort (a, b)->
      return b.dateUpdated - a.dateUpdated

    # extract all of the addresses from all of the homes
    # these should be in order of latest to oldest
    # the first one of a particular address found
    # will be the most recent visit

    homesByAddress = {}
    sorted.forEach (response)->
      # records for each address -- this rebuilds the history for each unique address
      existing = homesByAddress[response.key]
      # existing could be undefined, that's ok
      hm = addResponseToHistory existing, response

      homesByAddress[hm.key] = hm

    homesArray = []
    for k,h of homesByAddress
      homesArray.push h

    return [homesArray, homesByAddress]

  service.responsesToHomes = responsesToHomes

  retreiveResponsesFromLocalStorage()
  service

