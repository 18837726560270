'use strict'
angular.module('canvass').factory "Customer", ($q
  , $rootScope
  , $log
  , Now
  , Api
  , Offline
  , LocalStorage
  , Util
  , Messages
) ->
  service = Api('Customer')
  apiSome = service.some
  apiOne = service.one
  apiSave = service.save

  sync =  ()->
    $log.log 'syncing customer -----------------------------------'
    service.getCurrentCustomer()

  service.sync = Util.debounce sync, 60000, true

  service.save = (customer, convertToIds)->
    ensureRoutesUsers customer
    apiSave(customer, convertToIds)

  ensureRoutesUsers = (customer)->
    if !customer.routes
      customer.routes = []
    customer.routes = customer.routes.filter (route)->
      route != null

    if !customer.users
      customer.users = []
    customer.users = customer.users.filter (user)->
      user != null

  setCurrentCustomer = (c)->
    changed = c?._id != service.currentCustomer?._id
    service.currentCustomer = c
    LocalStorage.currentCustomer = JSON.stringify service.currentCustomer
    LocalStorage.currentCustomerTimestamp = Now().getTime()
    LocalStorage.currentCustomers = JSON.stringify service.currentCustomers
    # we may be just updating the reference
    # with an up-to-date copy of the customer
    # If we're actually changing the customer
    # then we need to process the messaging stuff
    if changed && Messages
      Messages.setCustomer c, service.currentCustomers

    return


  service.one = (id)->
    deferred = $q.defer()
    apiOne(id)
    .then (customer)->
      ensureRoutesUsers customer
      deferred.resolve customer
    .catch (err)->
      deferred.reject err

    deferred.promise


  service.some = (params, populateFields)->
    deferred = $q.defer()
    apiSome(params, populateFields)
    .then (customers)->
      #ensure routes and users are not valid
      #this is just a cleanup method
      #resulting from a bug that corrupted this data
      customers.forEach (customer)->
        ensureRoutesUsers customer

      deferred.resolve customers

    .catch (err)->
      deferred.reject err

    deferred.promise

  service.getCurrentCustomers = ()->
    deferred = $q.defer()

    if service.currentCustomers?.length
      deferred.resolve service.currentCustomers
    else
      getCurrentCustomer().then(()->
        $log.log 'got current customer', service.currentCustomer
        deferred.resolve service.currentCustomers
      ).catch (err)->
        $log.error err

    return deferred.promise

  getCurrentCustomer = ()->

    deferred = $q.defer()

    if !$rootScope.currentUser
      deferred.reject 'not logged in'
      return deferred.promise

    if service.currentCustomer
      deferred.resolve service.currentCustomer
      return deferred.promise


    if LocalStorage.currentCustomerTimestamp
      age = Now().getTime() - LocalStorage.currentCustomerTimestamp
      if ( age < 5000) # 5 seconds
        # use the locally cached version
        c = JSON.parse LocalStorage.currentCustomer
        if c
          if LocalStorage.currentCustomers
            service.currentCustomers = JSON.parse LocalStorage.currentCustomers
          setCurrentCustomer c
          $log.log 'using locally cached customer', c
          deferred.resolve c
          return deferred.promise


    # get the current customer from localstorage,
    # so we can keep the previously selected one
    # we'll stll get a new copy of that customer if we're online
    if LocalStorage.currentCustomer
      try
        c = JSON.parse LocalStorage.currentCustomer
        if (c == 'null' || c == 'undefined')
          c = null
        else
          # ensure that this user is for this customer
          # (handle changing logins)
          user = c?.users.find (u)->
            u._id == $rootScope.currentUser._id
          if user
            $log.log 'user found', user
          else
            c = null
            $log.log 'switching customers'

      catch e
        $log.error 'unable to get current Customer from local storage', e
        deferred.reject 'unable to get current Team'
        return deferred.promise
    else
      c = null

    if c
      if LocalStorage.currentCustomers
        service.currentCustomers = JSON.parse LocalStorage.currentCustomers

      if Offline.state == 'down'
        $log.log 'using cached customer'
        setCurrentCustomer c
        deferred.resolve c
        return deferred.promise

    else
      if Offline.state == 'down'
        # first time, or switch customer/team
        $log.log 'unable to get current customer while offline'
        deferred.resolve null
        return deferred.promise

    # we may or may not have a currentCustomer
    # at this point

    params =
      users: $rootScope.currentUser._id

    $log.log 'about to get current customers'
    service.some(params)
    .then (customers)->
      if !customers.length
        deferred.resolve null
        return deferred.promise

      # keep the current customer selected if possible.
      # else just default to the first one.
      # note: multiple customers are generally only for admins
      customerid = c?._id
      service.currentCustomers = customers
      if customerid
        c = customers.find (cust) -> cust._id == customerid
      else
        # first time in, or no matching customer (removed from team???)
        #$log.log 'setting current customer default'
        c = customers[0]

      # possibly null - guessing this would clear current customer.
      setCurrentCustomer  c
      if c
        deferred.resolve service.currentCustomer

      else
        deferred.resolve null

    .catch (err)->
      $log.error 'error getting current customer', err
      deferred.resolve null

    return deferred.promise

  userchanged = (newUser, oldUser)->
    return if !newUser
    return if oldUser && (newUser._id == oldUser._id)

    $log.log 'user changed'
    setCurrentCustomer null
    getCurrentCustomer()


  $rootScope.$watch 'currentUser', userchanged

  service.getCurrentCustomer = getCurrentCustomer
  service.setCurrentCustomer = setCurrentCustomer

  service.resetCurrentCustomer = ()->
    LocalStorage.currentCustomer = null
    LocalStorage.currentCustomers = null
    service.currentCustomers = null
    setCurrentCustomer null
    $rootScope.currentCustomer = null
    $rootScope.currentCustomers = null

  return service
