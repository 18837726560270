'use strict'

app.controller 'SimpleSurveyCtrl', ( $scope
  , $log
  , $rootScope
  , GeoLoc
  , LocalStorage
) ->
  onAdminForm = undefined

  $scope.titles = ['Mr','Mrs']


  $scope.isDirty = (val) ->
    tv = typeof (val)
    tv isnt "undefined"

  $scope.openDate = (e) ->
    e.preventDefault()
    e.stopPropagation()
    $scope.dateOpened = true
    return

  $scope.doorAnswered = (val)->
    #$log.log "doorAnswered"
    $scope.footer.display = false
    $scope.response.questions.doorAnswered = val
    return  if $scope.onAdminForm
    delete $scope.response.questions.finishedPitch
    return

  $scope.finishedPitch = (val)->
    #$log.log "finishedPitch"
    $scope.response.questions.finishedPitch = val
    return  if $scope.onAdminForm
    delete $scope.response.questions.gotLead
    return

  $scope.gotLead = (val)->
    #$log.log "gotLead"
    $scope.response.questions.gotLead = val
    return  if $scope.onAdminForm
    delete $scope.response.questions.homeowner
    if !val
      resetObjections()

    if !$scope.response.questions.lead
      $scope.response.questions.lead =
        appointmentDate: (new Date()).setMinutes(0)
      
    return

  resetObjections = ()->
    #$log.log 'reset objections'
    delete $scope.response.questions.money
    delete $scope.response.questions.timeForPitch
    delete $scope.response.questions.timeForEstimate
    delete $scope.response.questions.notInterested

  $scope.homeowner = (val)->
    #$log.log 'homeowner'
    $scope.response.questions.homeowner = val
    resetObjections()

    
  $scope.saveAttitude = ()->
    LocalStorage.attitude = $scope.response.questions.attitude

  return
