'use Strict'
# coffeelint: disable=max_line_length
class TileCache
  constructor: (@zoom = 15) ->
    @cache = {}
    @currentBounds = null
    @currentTileCoords = null

  getTileBounds: (lat, lon) ->
    if @currentBounds and @isInCurrentTile(lat, lon)
      return @currentBounds

    key = @getTileKey(lat, lon)
    if @cache[key]
      @currentBounds = @cache[key]
      @currentTileCoords = {x: Math.floor(key / 1000000), y: key % 1000000}
      return @currentBounds

    bounds = @calculateTileBounds(lat, lon)
    @cache[key] = bounds
    @currentBounds = bounds
    @currentTileCoords = {x: Math.floor(key / 1000000), y: key % 1000000}
    bounds

  isInCurrentTile: (lat, lon) ->
    return false unless @currentBounds
    [x1, y1, x2, y2] = @currentBounds
    lon <= x1 and lon > x2 and lat <= y1 and lat > y2

  getTileKey: (lat, lon) ->
    numTiles = 1 << @zoom
    x = Math.floor(numTiles * (0.5 + lon / 360))
    y = Math.floor(numTiles * (0.5 - Math.log((1 + Math.sin(lat * Math.PI / 180)) / (1 - Math.sin(lat * Math.PI / 180))) / (4 * Math.PI)))

    x * 1000000 + y  # Combine x and y into a single integer key

  calculateTileBounds: (lat, lon) ->
    numTiles = 1 << @zoom
    x = numTiles * (0.5 + lon / 360)
    y = numTiles * (0.5 - Math.log((1 + Math.sin(lat * Math.PI / 180)) / (1 - Math.sin(lat * Math.PI / 180))) / (4 * Math.PI))

    tileX = Math.floor(x)
    tileY = Math.floor(y)

    x1 = tileX * 360 / numTiles - 180
    y1 = Math.atan(Math.sinh(Math.PI * (1 - 2 * tileY / numTiles))) * 180 / Math.PI
    x2 = (tileX + 1) * 360 / numTiles - 180
    y2 = Math.atan(Math.sinh(Math.PI * (1 - 2 * (tileY + 1) / numTiles))) * 180 / Math.PI

    [x1, y1, x2, y2]

app.factory 'Maps', (
  $q,
  $log,
  $state,
  $rootScope,
  Util,
  Now,
  GeoLoc,
  SurveyResponse
) ->

  $rootScope.$on 'homeSaved', (e, h)->
    try
      # I should have a better way to determine which of these three states the edit is

      # if this is an edit of an existing visit, h.queueStatus will be 'update'
      # and the id will be a mongo id
      # if this is a new visit (new surveyResponse) for an existing home,
      # then we can find the existing home by the last entry in the history
      # which should be the id of the home in the map
      # otherwise, this is a completely new home


      home = SurveyResponse.allHomesByAddress[h.key]
      if !home
        throw new Error('home not found', h)

      # this will always return a tile, even if it's adding a new home
      [tile, found, tileKey] = getTileForHomeKey home.key
      if found
        index = tile.findIndex (eHome)-> eHome.key == home.key
        if index > -1
          # found the home
          console.log 'found home, updating it'
          updateHomeInTile tile, home, index, tileKey
        else
          console.error 'did not find the home after all, should not be possible'
      else
        # adding a home
        console.log 'adding a home to a tile', home, tile
        tile.unshift h
        updateHomeInTile tile, home, 0, tileKey

      updateMap()
    catch ex
      console.error ex

  updateHomeInTile = (tile, h, index, tileKey)->
    prev = tile[index]
    # update home in the tile
    tile[index] = h

    cacheHomes tile, tileKey


  # returns array with the tile, and whether or not the home was found in an existing tile
  getTileForHomeKey = (homeKey)->
    tileKey = service.homeTileKeys[homeKey]
    if tileKey
      return [service.tileHomes[tileKey], true, tileKey]
    else
      # I don't think it matters which tile I choose for 'adding' homes
      tileKey = Object.keys(service.tileHomes)[0]
      return [service.tileHomes[tileKey], false, tileKey]

  cacheHomes = (homes, tileKey)->

    # store the home > tile reference
    homes.forEach (h)->
      exists = service.homeTileKeys[h.key]
      if exists
        console.log 'home exists, tilekey, h.key', exists, tileKey, h.key
      else
        service.homeTileKeys[h.key] = tileKey

    # then the tile > home(s) reference
    service.tileHomes[tileKey] = [homes...]

  boundsKey = (bounds)->
    bounds.join ','

  getHomesAtTile = (bounds)->
    key = boundsKey bounds
    if !service.tileHomes
      service.tileHomes = {}
    found = service.tileHomes[key]
    if found
      # console.log 'using cached homes at tile', key
      Promise.resolve [found, key]
    else
      # console.log 'getting new homes for tile', key
      gmBounds = service.createBoundsFromArray bounds
      # drawRectangle gmBounds
      SurveyResponse.getResponsesInArea(gmBounds)
      .then (responses)->

        [homes, byAddress] = service.responsesToHomes responses

        # cache the de-duped version of responses
        cacheHomes homes, key

        # set the global cache
        # this is used by SurveyResponse.findHome
        SurveyResponse.cacheHomes byAddress

        [homes, byAddress]

      .catch (e)->
        $log.error 'error getting homes', e

  getHomesAroundPoint = (lat, lng, bounds)->
    # this is optimized/cached
    # returns an array of x, y points (lng, lat)
    tiles = service.getTilesAroundPoint lat, lng
    getHomes = tiles.map ([lng, lat])->
      boundsArray = service.getTileBounds lat, lng
      getHomesAtTile boundsArray

    Promise.all getHomes
    .then (tiles)->
      homesPerTile = tiles.map ([homes, byAddress])-> homes
      # not sure how this will be used yet
      allAddresses = SurveyResponse.allHomesByAddress

      tiles.forEach ([homes, byAddress])->
        Object.keys(byAddress)
        .forEach( (k) -> allAddresses[k] = byAddress[k])

      homes = homesPerTile.flat()
      filtered = homes.filter (h)->
        bounds.contains { lat: h.position.coords.latitude, lng: h.position.coords.longitude }

      # to viewport
      filtered

  # Function to cluster the data
  clusterData = (data, radius) ->
    index = new Supercluster
      radius: radius * 100  # Supercluster uses pixels, so we multiply by 100 for approximate conversion
      maxZoom: 16  # Adjust this based on your needs

    # Add points to the index
    index.load data.features.map (feature) ->
      type: 'Feature'
      geometry: feature.geometry
      properties: feature.properties

    index

  # Function to clear all features from the data layer
  clearMap = (map)->
    map.data.forEach (feature) ->
      map.data.remove(feature)

  # Function to calculate clustering radius based on zoom and viewport
  calculateClusterRadius = (zoom, bounds) ->
    # Base radius in pixels
    baseRadius = 40

    # Adjust radius based on zoom level
    Math.max(10, baseRadius / Math.pow(2, zoom - 10))

  mapDecode = (val)->
    tmp = val.replace(/,/g,'\\').replace(/-/g,'\/')
    decoded = google.maps.geometry.encoding.decodePath(tmp)
    decoded

  mapEncode = (val)->
    tmp = google.maps.geometry.encoding.encodePath(val)
    encoded = tmp.replace(/\\/g,',').replace(/\//g,'-')
    encoded

  polyAddEvents = (obj)->
    path = obj.getPath()

    obj.getBounds = ()->
      bounds = new google.maps.LatLngBounds()
      for num in (num for num in [0..path.getLength()-1])
        do ()->
          point = path.getAt num
          bounds.extend point
      bounds

    obj.addPoint = (e)->
      return if !path
      path.push e.latLng

    obj.removePoint = ()->
      return if !path
      path.pop()

    obj.serialize = ()->
      output = mapEncode path
      output

    obj.deSerialize = (serialized)->
      return if !serialized

      input = mapDecode(serialized)
      obj.setPath input
      path = obj.getPath()

    google.maps.event.addListener obj, 'rightclick', (mev)->
      if mev.vertex != null
        path.removeAt mev.vertex

    obj.onChange = (cb)->
      google.maps.event.addListener path, 'insert_at', cb
      google.maps.event.addListener path, 'remove_at', cb
      google.maps.event.addListener path, 'set_at', cb

    return obj

  service = {
    homeTileKeys: {}
  }

  service.getHomesAroundPoint = getHomesAroundPoint
  service.calculateClusterRadius = calculateClusterRadius
  service.clearMap = clearMap
  service.clusterData = clusterData

  service.init = ()->
    return if service.leadPath

    service.leadPath = 'M256,50C142.229,50,50,142.229,50,256c0,113.771,92.229,\
        206,206,206c113.771,0,206-92.229,206-206C462,142.229,369.771,50,256,\
        50z M274.885,352.508v24.019h-34.363v-21.482c-16.493-1.411-38.272-7.\
        39-54.32-16.23l11.37-39.603c18.172,7.088,44.685,16.001,64.29,11.721c17.\
        149-3.74,21.471-19.137,1.288-27.836c-17.048-7.655-75.714-15.658-75.714-64\
        .722c0-20.326,15.235-48.262,53.086-56.524v-24.375h34.363v22.368c14.727,\
        1.012,29.748,3.731,47.096,9.156c-1.736,7.579-9.234,40.472-9.234,40.472\
        c-11.979-4.076-31.559-11.203-50.357-10.146c-23.227,1.306-24.891,\
        18.268-9.232,26.099c32.121,14.525,78.643,26.878,78.643,70.2C331.844,\
        329.101,305.762,348.167,274.885,352.508z'

    currentPositionIcon = document.createElement('div')
    currentPositionIcon.className = 'current-position-icon'

    service.currentPositionIcon = currentPositionIcon


  service.goToPosition = (pos)->
    latlng = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude)
    service.map?.panTo(latlng)

  service.setZoom = (v)->
    service.map?.setZoom(v)

  service.markerFromLatLng = (pos, title)->
    return new google.maps.marker.AdvancedMarkerElement
      position: pos
      title: title

  service.makearea = (map, color)->
    areaOptions =
      strokeColor: '#000000'
      strokeOpacity: 1.0
      strokeWeight: 1
      fillColor: color
      fillOpacity: .5

    area = new google.maps.Polygon(areaOptions)
    area.setMap(map)
    polyAddEvents area

    return area

  service.makeLine = (map)->
    lineOptions =
      strokeColor: '#000000'
      strokeOpacity: 1.0
      strokeWeight: 1
      icons: [
        icon:
          path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
          strokeColor: '#292'
          strokeWeight: 3
        offset: '0'
        repeat: '100px'
      ]

    line = new google.maps.Polyline(lineOptions)
    line.setMap(map)

    polyAddEvents line

    return line


  service.createMap = (pos, zoom, mapElement)->
    if !mapElement
      $log.error 'no MapElement, cannot create map', ex
      return
    if !google.maps
      $log.log 'no map api available'
      return

    pos = pos || GeoLoc.centerOfUSA

    coords = pos.coords

    try
      options =
        zoom: zoom
        center:
          lat: coords.latitude
          lng: coords.longitude
        fullscreenContro: true
        mapTypeControlOptions:
          style: google.maps.MapTypeControlStyle.DEFAULT
          position: google.maps.ControlPosition.RIGHT_TOP
        fullscreenControlOptions:
          position: google.maps.ControlPosition.RIGHT_TOP
        gestureHandling: 'greedy'
        mapId: 'canvass_app_map'

      $log.log 'options', options

      map =  new google.maps.Map(mapElement, options)

      #map.setCenter posOpts.center

      map.addListener = (eventName, cb)->
        google.maps.event.addListener(map, eventName, cb)

      service.map = map
      service.data = map.data

      map.data.setStyle (f)->

        if f.getProperty('isCluster')
          pointCount = f.getProperty('pointCount')

          return {
            icon:
              path: google.maps.SymbolPath.CIRCLE
              fillColor: '#4285F4'
              fillOpacity: 0.7
              scale: Math.log2(f.getProperty('pointCount')) * 5
              strokeColor: '#FFFFFF'
              strokeWeight: 2
            label: pointCount
          }
        else
          r = f.getProperty 'response'
          if (r)
            color = f.getProperty 'color'
            updatedToday = f.getProperty 'updatedToday'

            return {
              icon: getIcon r, color, updatedToday
            }

          console.log 'invalid point'
          return {
            fillColor: 'red'
            pointRadius: 10
          }

      service.data.addListener 'click', (e)->
        feature = e.feature
        if feature.getProperty('isCluster')
          map.setZoom(map.getZoom() + 2)
          map.panTo(
            lat: feature.getGeometry().get().lat()
            lng: feature.getGeometry().get().lng()
          )
        else
          clickFeature feature.getId()

      return map

    catch ex
      $log.error 'exeption drawing map', ex
    return

  eraseMarker = (marker)->
    # $log.log 'erase marker', marker
    if marker
      marker.setMap null
      google.maps.event.clearListeners marker, 'click'
      delete service.homeMarkers[marker._id]

  service.eraseCurrentPositionMarker = ()->
    eraseMarker service.currentPositionMarker

  service.erasePickupMarker = ()->
    eraseMarker service.pickupMarker

  service.eraseDropoffMarker = ()->
    eraseMarker service.dropoffMarker

  service.routes = {}

  service.homeMarkers = {}

  service.eraseHome = (id)->
    # $log.log 'erasing home', id
    marker = service.homeMarkers[id]
    if marker
      eraseMarker marker

  service.eraseHomes = ()->
    $log.log 'erasing all homes'
    for k,v of service.homeMarkers
      eraseMarker v

  service.displayHome = (r)->

    marker = service.homeMarkers[r._id]
    if marker

      if ( marker.key != markerKey(r) )
        # ensures that the marker will be redone with new colors
        eraseMarker marker
      else
        # this home exists, and it's current status
        # isn't different so no need to redraw
        console.log 'skipping drawing home', r
        return


    if (r.position || r.canvasserPosition)
      console.log 'actually creating marker'
      marker = service.makeMarker(r)
      service.homeMarkers[r._id] = marker
      #$log.log 'marker', marker

    else
      $log.log 'invalid gps location for ', r

  getCanvasserId = (r)->
    r.canvasser._id || r.canvasser

  service.responsesToHomes = SurveyResponse.responsesToHomes

  service.hideDataLayer = ()->
    service.data.setMap(null)

  service.showDataLayer = ()->
    if !service.data.getMap()
      service.data.setMap(service.map)

  # unused
  service.displayResponses = (responses)->
    new Promise (resolve, reject)->

      if !responses
        return

      [homes, byAddress] = SurveyResponse.responsesToHomes(responses)

      features = homes.map featureFromResponse
      featureCollection =
        type: "FeatureCollection"
        features: features

      console.log 'adding features to map', featureCollection
      service.map.data.addGeoJson featureCollection, 'id'
      resolve [homes, byAddress]

  featureFromResponse = (r)->
    id = r._id || r.tmpId
    type: "Feature"
    id: id
    geometry:
      type: "Point"
      coordinates: [r.position.coords.longitude, r.position.coords.latitude]
    properties:
      id: id
      response: r
      updatedToday: isUpdatedToday r
      color: Util.colorFromStatus r

  service.featureFromResponse = featureFromResponse

  clickFeature = (id)->
    $state.go 'canvass.surveyResponseaction', {id:id, action: 'visit'}

  clickMarker = ()->
    $state.go 'canvass.surveyResponseaction', {id:this._id, action: 'visit'}

  isUpdatedToday = (r)->
    updatedDate = new Date(r.dateUpdated).setHours(0,0,0,0)
    todayDate = Now().setHours(0,0,0,0)
    return updatedDate == todayDate

  isUpdatedTodayByCurrentUser = (r)->
    isUpdatedToday(r) && (getCanvasserId(r) == $rootScope.currentUser._id)

  markerKey = (r)->

    key =
      lead: r.questions?.gotLead
      date: new Date(r.dateUpdated).setHours(0,0,0,0)
      color: Util.colorFromStatus(r)
      updatedToday: isUpdatedToday(r)

    JSON.stringify key

  getStrokeColor = (r, updatedToday)->
    if updatedToday then 'black' else ''

  getStrokeWeight = (r, updatedToday)->
    if updatedToday then 3 else 0

  getIconScale = (r)->
    if r.questions?.gotLead then .08 else 10

  getIconAnchor = (r)->
    if r.questions?.gotLead then new google.maps.Point(300,300) else undefined

  getIconFillOpacity = (r, updatedToday)->
    if updatedToday then 1 else .6

  getIconPath = (r)->
    result = google.maps.SymbolPath.CIRCLE

    if r.questions?.gotLead
      result = service.leadPath
    return result

  getIcon = (r, color, updatedToday)->
    fillColor: color
    fillOpacity: getIconFillOpacity(r, updatedToday)
    strokeWeight: getStrokeWeight(r, updatedToday)
    strokeColor: getStrokeColor(r, updatedToday)
    scale: getIconScale(r)
    rotation: 0
    path: getIconPath(r)
    anchor: getIconAnchor(r)

  service.markerFromCoord = (coord, title, icon, zIndex = 1, map)->
    opts =
      position: (new google.maps.LatLng(coord.latitude, coord.longitude)),
      title: title,
      icon: icon,
      zIndex: zIndex,
      map: map
    return new google.maps.marker.AdvancedMarkerElement opts

  service.makeMarker = (r)->
    try
      pos = r.canvasserPosition || r.position

      updatedToday = isUpdatedTodayByCurrentUser(r)
      color = Util.colorFromStatus r

      icon = getIcon r, color, updatedToday

      zIndex = if r.questions?.gotLead then 20 else 1

      marker = service.markerFromCoord(
        pos.coords, r.questions.lead?.address, icon, zIndex, service.map
      )

      marker._id = r._id
      marker.key = markerKey r
      marker.color = color

      google.maps.event.addListener marker, 'click', clickMarker

      # $log.log 'marker', marker
      return marker

    catch ex
      console.log 'error seticon', ex
      return undefined


  # smaller number is larger area
  tileCache = new TileCache(13) # 14
  service.getTileKey = (lat, lng)->
    tileCache.getTileKey lat, lng
  service.getTileBounds = (lat, lng)->
    tileCache.getTileBounds(lat, lng)

  service.getTilesAroundPoint = (lat, lng)->
    centerBounds = tileCache.getTileBounds lat, lng
    [x1, y1, x2, y2] = centerBounds
    # tiles = [
    #   #center row
    #   [-1, 0],
    #   [0,  0],
    #   [+1, 0],
    #   #top row
    #   [-1, -1],
    #   [0,  -1],
    #   [+1, -1]
    #   #bottom row
    #   [-1, +1],
    #   [0,  +1],
    #   [+1, +1]
    # ]
    # for debugging, fewer reponses
    tiles = [[0,0]]
    points = []
    xDistance = Math.abs(x2 - x1)
    yDistance = Math.abs(y2 - y1)
    for cell in tiles
      [xDirection, yDirection] = cell
      # move to new tile
      x = lng + xDistance * xDirection
      y = lat + yDistance * yDirection
      points.push [x,y]

    points

  service.createBoundsFromArray = (coordArray) ->
    [x1, y1, x2, y2] = coordArray
    sw = new google.maps.LatLng(y1, x2)
    ne = new google.maps.LatLng(y2, x1)
    # $log.log 'ne, sw', ne, sw
    new google.maps.LatLngBounds(ne, sw)

  service.drawRectangle = (bounds, map)->
    ne = bounds.getNorthEast()
    sw = bounds.getSouthWest()
    boundsRect =
      strokeColor: '#ff0000'
      strokeWeight: 2
      map: map
      bounds:
        north: ne.lat()
        south: sw.lat()
        east: ne.lng()
        west: sw.lng()

    $log.log 'bounds rect', boundsRect

    new google.maps.Rectangle boundsRect

  # Function to update the map with new data
  updateMap = ->
    map = service.map
    # console.log 'update map'
    bounds = map.getBounds()
    center = map.getCenter()
    zoom = map.getZoom()

    radius = service.calculateClusterRadius zoom, bounds

    # Get data for the current viewport
    # getDataForViewport(bounds, center).then (data) ->
    service.getHomesAroundPoint(center.lat(), center.lng(), bounds).then (data) ->

      # data is an array of homes/responses
      service.clearMap(map)
      if !data.length
        return

      featureCollection =
        type: "FeatureCollection"
        features: data.map service.featureFromResponse

      index = service.clusterData(featureCollection, radius)

          # Get clusters for the current viewport
      bbox = [
        bounds.getSouthWest().lng()
        bounds.getSouthWest().lat()
        bounds.getNorthEast().lng()
        bounds.getNorthEast().lat()
      ]
      clusters = index.getClusters(bbox, Math.floor(zoom))

      # Add clusters and points to the map
      clusters.forEach (cluster) ->
        if cluster.properties.cluster
          # It's a cluster
          feature = new google.maps.Data.Feature
            geometry: new google.maps.LatLng(cluster.geometry.coordinates[1], cluster.geometry.coordinates[0])
            properties:
              pointCount: cluster.properties.point_count
              isCluster: true
          map.data.add(feature)
        else
          # It's a single point
          map.data.addGeoJson(cluster, { idPropertyName: 'id'})

  service.updateMap = updateMap
  service.clearCache = ()->
    service.tileHomes = {}
    service.homeTileKeys = {}
    service.updateMap()
  return service