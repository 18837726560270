# coffeelint: disable=max_line_length
HomeCtrl = ($scope
, $rootScope
, $window
, $q
, $log
, $state
, $interval
, $timeout
, Maps
, GeoLoc
, Messages
, currentSurvey
, currentPosition
, onlineState # need to wait until this is confirmed,
              # so that $scope.online is valid
, SurveyResponse
, Util
, map
) ->
  console.log 'loading home controller'
  $scope.map = map

  $scope.zoomToLocation = ()->
    pos = $rootScope.currentUser.position
    if pos
      Maps.goToPosition pos

  $scope.newSurvey = ()->
    console.log 'click newSurveyResponse'
    $state.go 'canvass.surveyResponse'

  $scope.goToMyLeads = ()->
    console.log 'click leads'
    $state.go 'canvass.myLeads'

  $scope.$on '$destroy', (e)->
    $interval.cancel gmapsCheck
    google.maps.event.removeListener panoListener
    google.maps.event.removeListener boundsListener
    google.maps.event.removeListener mapUpdateListener
    # remove angular events
    posUpdatedListener()

  $window.scrollTo(0,1)
  $scope.messages = Messages
  $scope.goMessages = ()->
    $state.go 'canvass.messages'

  $scope.currentSurvey = currentSurvey

  gmapsCheck = $interval ()->
    # check for need to load google maps apis (caused by app load while offline)
    return if !$scope.online

    if !google.maps || !google.maps.Map
      # this case only happens when the app is
      # loaded while offline
      # it will not happen if the app goes
      # offline temporarily
      $window.location.reload()
  , 1000

  saveValue = (k, v)->
    localStorage[k] = JSON.stringify v
    $rootScope[k] = v

  saveLastPosAndZoom = ionic.Utils.throttle((pos, zoom)->
    saveValue 'lastPosition', pos
    saveValue 'lastZoomLevel', zoom
    # $log.log 'zoom', zoom
  , 1000, { trailing: true})

  $rootScope.hideTip = JSON.parse(sessionStorage.hideTip || 'false')

  if (!(window.canvassApp.appInstalled && $rootScope.hideTip ))
    $log.log 'app not installed'
    setTimeout ->
      google.maps.event.addListenerOnce(map, 'idle', ->
        $log.log 'hiding tip window'
        $rootScope.hideTip = true
        sessionStorage.hideTip = JSON.stringify true
      )
    , 400
  else
    $log.log 'app installed already'


  boundsListener = map?.addListener 'idle', ionic.Utils.throttle ->

    bounds = map.getBounds()
    pos = map.getCenter()
    pos =
      lat: pos.lat()
      lng: pos.lng()

    zoom = map.getZoom()
    saveLastPosAndZoom pos, zoom
    $rootScope.lastBounds = bounds

  , 500, { trailing: true }


  panoListener = null
  setStreetviewHandlers = (map)->
    pano = map.getStreetView()
    panoListener = pano.addListener 'visible_changed', ()->
      # $log.log('streetview visible_changed')
      $scope.streetViewVisible = pano.getVisible()


  updatePosition = (pos)->
    $log.log 'position updated'

    return if !$scope.online

    if pos?.coords

      latlng =
        lat: pos.coords.latitude
        lng: pos.coords.longitude

    if !Maps.currentPositionMarker

      Maps.currentPositionMarker = new google.maps.marker.AdvancedMarkerElement
        map: map
        title: 'I am here'
        content: Maps.currentPositionIcon

      Maps.gpsAccuracy = new google.maps.Circle
        map: map
        fillColor: 'cornflowerblue'
        fillOpacity: .5
        strokeWeight: 0


    # update position and accuracy circle radius every time
    try
      if latlng
        Maps.currentPositionMarker.position = latlng
        Maps.gpsAccuracy.setRadius pos.coords.accuracy
        Maps.gpsAccuracy.setCenter latlng
    catch e
      $log.error(e, 'latlng', latlng)

    return


  posUpdatedListener = $scope.$on 'positionUpdated', updatePosition

  loadMap = ()->
    wrapper = document.getElementsByClassName('mapWrapper')[0]
    if wrapper.lastChild != Maps.mapElement
      wrapper.appendChild(Maps.mapElement)
      $log.log('triggering resize')
      google.maps.event.trigger(map, 'resize')

  ready = ()->

    return if !google.maps

    if map
      loadMap()
      setStreetviewHandlers(map)
      setupSearch(map)
      updatePosition currentPosition

    return null


  searchBoxElement = null
  setupSearch = (map)->
    $log.log 'setupSearch'
    if !map
      $log.log 'no map, returning'
      return

    homeView = document.querySelector('.home-view')
    searchBoxElement = homeView.querySelector('#searchBox')
    if (!searchBoxElement)
      $log.log 'cant find search box element'
      return

    # top = google.maps.ControlPosition.TOP_RIGHT
    # Maps.map.controls[top].push searchBoxElement

    autocomplete = new google.maps.places.Autocomplete(searchBoxElement)
    autocomplete.bindTo('bounds', map)

    autocomplete.addListener 'place_changed', ()->
      place = autocomplete.getPlace()
      if (!place)
        $log.log 'no search results'
        return
      if place?.geometry?.location
        map.panTo place.geometry.location
      else
        $log.log 'no location for that place'
      return

  $scope.disableTap = ()->
    # need a timeout to avoid warning about
    # accessing dom elements during template event
    $timeout ()->

      container = document.getElementsByClassName('pac-container')
      angular.element(container)
        .attr('data-tap-disabled', 'true')
        .unbind('click').on "click", ()->
          document.getElementById('searchBox').blur()
    , 100

  $scope.closeToast = ()->
    $scope.toast = ''
    $scope.toastClass = ''

  onGpsError = (e, data)->
    $scope.toast = data
    $scope.toastClass = 'error'

  $rootScope.$on 'gpsError', onGpsError

  if GeoLoc.gpsError
    onGpsError('error', GeoLoc.gpsError)

  updateMap = Maps.updateMap

  # Throttled version of updateMap to avoid too frequent updates
  throttledUpdateMap = ionic.Utils.throttle(updateMap, 100)

  # unused for now
  getDataForViewport = (bounds, center)->

    # drawRectangle bounds
    SurveyResponse.getResponsesInArea(bounds)
    .then (data)->
      return Maps.responsesToHomes data


  # Set up map event listeners
  mapUpdateListener = google.maps.event.addListener map, 'idle', throttledUpdateMap
  # google.maps.event.addListener map, 'zoom_changed', throttledUpdateMap

  # loads the map
  ready()

  # end of homeCtrl
  return

getValueFromRootScopeOrLocalStorage = ($rootScope, key)->
  return new Promise (resolve, reject)->
    if key of $rootScope
      resolve $rootScope[key]
      return

    if key of localStorage
      try
        v = JSON.parse localStorage[key]
        resolve v
        return
      catch ex
        $log.error "error getting value [#{key}]", ex
        resolve null
        return
    else
      resolve null
      return

HomeCtrl.resolve = {
  onlineState: (Online, $q, $log)->
    deferred = $q.defer()
    Online.confirmedState().then (state)->
      $log.log 'resolving state'
      deferred.resolve state

    return deferred.promise

  currentSurvey: (Survey, $q,$log)->
    deferred = $q.defer()
    try

      Survey.getMySurvey().then( (survey)->
        deferred.resolve survey
      ).catch (e)->
        $log.error 'error resolving survey'
        deferred.reject e

    catch e
      $log.log 'unable to resolve current survey ', e
      deferred.reject 'unable to resolve current survey'
    return deferred.promise

  currentPosition: (GeoLoc, $q, $log)->

    deferred = $q.defer()
    try
      GeoLoc.getCurrentPosition().then( (pos)->
        deferred.resolve pos
      ).catch (e)->
        $log.error 'error resolving position', e
        deferred.resolve null

    catch e
      $log.log 'unable to resolve current position', e
      deferred.resolve null

    return deferred.promise

  lastZoomLevel: ($rootScope)->
    return getValueFromRootScopeOrLocalStorage($rootScope, 'lastZoomLevel')

  lastPosition: ($rootScope)->
    return getValueFromRootScopeOrLocalStorage($rootScope, 'lastPosition')
      .then (pos)->
        return pos if !pos

        coords:
          latitude: pos.lat
          longitude: pos.lng

  map: (Maps, currentPosition, lastPosition, lastZoomLevel, $log, GeoLoc, Util)->
    Util.waitForGoogle().then ()->
      return new Promise (resolve, reject)->

        if Maps.map
          resolve Maps.map
          return

        $log.log 'drawing map'
        lastZoomLevel = lastZoomLevel || 5

        mEl = Maps.mapElement ||
          document.getElementsByClassName('canvasserMap')[0]
        # if !mEl
          # alert('map not available - contact support')
        Maps.mapElement = mEl
        if mEl
          window.canvassApp.mapAPIReady$.then ()->
            $log.log 'map api ready'
            Maps.init()
            pos = GeoLoc.centerOfUSA
            if lastPosition
              pos = lastPosition
            else if currentPosition
              pos = currentPosition
            else
              # no last position, and no current position (no gps perms),
              # so pos === center of usa
              # so change the zoom
              lastZoomLevel = 5

            map = Maps.createMap(pos, lastZoomLevel, Maps.mapElement)
            ready = google.maps.event.addListener map, 'bounds_changed', ()->
              # map loaded
              google.maps.event.removeListener ready
              resolve map
          return

        else
          resolve undefined
          return


}

app.controller 'HomeCtrl', HomeCtrl

