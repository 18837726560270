

RoofingController = ($scope, Util)->
  
  # would be nice to define this elsewhere?
  # its getting redefined on each controller instance
  if !RoofSide
    class RoofSide

      constructor: (@location = '', @length = 0, @width = 0)->
        

      Object.defineProperties @prototype,
        area:
          get: ()->
            Util.roundUp(this.length * this.width, 100)

        areaWithPitch:
          get: ()->
            Util.roundUp(this.area * 1.24, 100)

  if not ('squares' of  $scope.model)
    Object.defineProperties $scope.model,
      squares:
        get: ()->
          this.sides.reduce( (memo, side)->
            memo + side.areaWithPitch
          , 0) / 100

      waste:
        get: ()->
          this.squares / 10

      totalSQ:
        get: ()->
          this.squares + this.waste

      basePerSq:
        get: ()->
          return 0 if !this.tearOff
          this.roofType.tearOffs[this.tearOff]

      underlayPerSq:
        get: ()->
          return 0 if !this.underlayment
          this.underlayment.price

      steepPitchPerSq:
        get: ()->
          return 0 if !this.roofPitch
          this.roofPitch.price

      totalPerSq:
        get: ()->
          this.basePerSq + this.underlayPerSq + this.steepPitchPerSq

      warrantyCost:
        get: ()->
          if this.warranty == "Golden Pledge Warranty"
            return 40 * this.totalSq
          else
            return 250

  $scope.warranties = [
    "Golden Pledge Warranty",
    "Weather Stopper System Plus"
  ]

  $scope.tearOffs = [
    "One Layer",
    "Two Layers",
  ]

  $scope.roofPitches = [
    name: "Regular Pitch"
    price: 0
  ,
    name: "Steep Pitch"
    price: 90
  ,
  ]

  $scope.roofTypes = [
    name: "Timberline Laminate Shingles"
    tearOffs:
      "One Layer" : 630
      "Two Layers" : 650
  ,
    name: "3-Tab Royal Sovereign Shingles"
    tearOffs:
      "One Layer" : 600
      "Two Layers" : 625
  ,
    name: "Timberline Woodland"
    tearOffs:
      "One Layer" : 1190
      "Two Layers" : 1220
  ]

  $scope.underlayments = [
    name: "Tiger Paw"
    price: 45
  ,
    name: "30lb Felt Paper"
    price: 0
  ,
    name: "Shingle Mate"
    price: 0
  ,
    name: "Deck Armor"
    price: 60
  ]

  $scope.ridgeVents = [
    "Existing Ridge Vent",
    "Cut a Ridge Vent (Townhouse)",
    "Cut a Ridge Vent (Single Family House)",
  ]

  if !$scope.model.sides
    $scope.model.sides = []

  # convert to class...
  for v,k in $scope.model.sides
    $scope.model.sides[k] = new RoofSide v.location, v.length, v.width

  $scope.addSide = ()->
    $scope.model.sides.push new RoofSide

  $scope.removeSide = (e, index)->
    e.preventDefault()
    $scope.model.sides.splice(index,1)

RoofingDirective = ()->
  restrict: 'E'
  templateUrl: 'directives/roofing/index.html'
  scope:
    model: '='
  controller: RoofingController

app.directive 'roofing', RoofingDirective
