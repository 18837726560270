'use strict'
app.factory 'Socket', (
  socketFactory
  , Config
  , $window
) ->
  if !$window.io
    return null

  socket = $window.io(Config.serverUri)
  return socketFactory(
    ioSocket: socket
  )
