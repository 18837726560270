
MyLeadsCtrl = (
  $scope,
  $log,
  $state,
  $ionicPopup,
  LocalStorage,
  SurveyResponse,
  User,
  Now,
  Maps,
  leads
)->

  $scope.leads = leads

  $scope.synced = true
  leads.forEach (lead)->
    if lead.queueStatus
      $scope.synced = false

  lastConfirmDay = new Date($scope.currentUser.lastConfirmLeads)
    .setHours(0,0,0,0)
  today = Now().setHours(0,0,0,0)
  $scope.confirmedToday = lastConfirmDay == today

  # $scope.click = (r)->
  #   $state.go 'canvass.surveyResponseid', {id:r._id}

  $scope.goToHome = (r, e)->
    e.preventDefault()
    e.stopImmediatePropagation()
    $state.go 'canvass.home'
    pos = r.position
    Maps.goToPosition pos
    Maps.setZoom 18

  $scope.sync = ()->
    SurveyResponse.sync()
    .then (responses)->
      if !responses
        $ionicPopup.alert(
          title: 'Sync error'
          template: 'We were unable to sync your data to the server, \
          likely because you are not online at the moment. \
          Please check your internet connection and try again.'
        )
        return

      now = Now()

      update =
        id: $scope.currentUser._id
        lastConfirmLeads: now

      #$log.log 'saving user lastconfirmLeads', update
      User.update update
      , (user)->
        #$log.log 'user.lastConfirmLeads', new Date(user.lastConfirmLeads)
        $scope.currentUser.lastConfirmLeads = now
        LocalStorage.currentUser = angular.toJson user
        # $log.log 'done saving user'
        $scope.confirmedToday = true
        $ionicPopup.alert(
          title: 'Leads Confirmed'
          template: 'Your leads are synced and confirmed!'
        )

      , (err)->
        $ionicPopup.alert(
          title: 'Sync error'
          template: 'We were unable to sync your data to the server, \
          likely because you are not online at the moment. \
          Please check your internet connection and try again.'
        )

      return
      
    .catch (err)->
      # alert
      $log.error 'error', err

      $ionicPopup.alert(
        title: 'Sync error'
        template: 'We were unable to sync your data to the server, \
        likely because you are not online at the moment. \
        Please check your internet connection and try again.'
      )

app.controller "MyLeadsCtrl", MyLeadsCtrl

MyLeadsCtrl.resolve = {
  leads: (SurveyResponse)->
    SurveyResponse.getMyLeads()
}
