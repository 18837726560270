'use strict'

window.canvassApp.mapAPIReady$ = new Promise (resolve, reject)->
  # unavoidable global due to how gmaps does the async load
  window.mapAPIReady = resolve

window.addEventListener 'unhandledrejection', (event)->
  # the event object has two special properties:

  # [object Promise] - the promise that generated the error
  console.error(event.promise)

  # Error: Whoops! - the unhandled error object
  console.error(event.reason)

if !!navigator.serviceWorker
  navigator.serviceWorker.getRegistrations().then( (registrations)->
    if localStorage.useSw ||
        (localStorage.appTimestamp != window.canvassApp.timestamp)

      url = '/sw.js?v=' + window.canvassApp.timestamp
      navigator.serviceWorker.register(url).then( (registration)->
        localStorage.appTimestamp = window.canvassApp.timestamp
        console.log('serviceWorker registration successful with scope: ' \
        , registration.scope)
      ).catch (err)->
        console.error 'serviceWorker registration failed: ', err
    # else
    #   # unregister that sw and any others
    #   registration.unregister() for registration in registrations

    navigator.serviceWorker.addEventListener 'controllerchange', ()->
      script = navigator.serviceWorker.controller.scriptURL
      console.log 'serviceworker.oncontrollerchange', script
      window.location.reload()
    console.log 'finished dealing with service workers'

  ).catch (err)->
    console.error 'error getting registration', err

window.app = angular.module("canvass", [
  'ionic',
  'ngResource',
  'ngCookies',
  'luegg.directives',
  'btford.socket-io',
  'ui.mask',
  'ui.bootstrap',
  'ui.select',
  'ngAnimate',
  'ngSanitize'
])

Offline.options =
  requests: false # do not replay failed requests
  checkOnLoad: true
  interceptRequests: false #for testing connectivity.but too noisy
  checks:
    image:
      url: 'img/1px.gif'
    active: 'image'

app.config ($stateProvider,
  $urlRouterProvider,
  $httpProvider,
  $controllerProvider,
  $provide,
  $ionicConfigProvider,
  $sceDelegateProvider
) ->


  $ionicConfigProvider.backButton.previousTitleText(false).text('')

  $sceDelegateProvider.resourceUrlWhitelist([
    'self',
    "http://#{window.canvassApp.apiServer}/**",
    "https://#{window.canvassApp.apiServer}/**",
    "https://maps.googleapis.com/**"
  ])

  app._controller = app.controller
  app.controller = (name, constructor)->
    $controllerProvider.register name, constructor
    this

  $provide.decorator '$log', ($delegate, Logger)->
    #return Logger($delegate)
    if window.canvassApp.environment != 'development'
      return Logger($delegate)
    else
      return console

  # set up cors requests
  $httpProvider.defaults.useXDomain = true
  delete $httpProvider.defaults.headers.common['X-Requested-With']

  $stateProvider
  .state('canvass',
    cache: false
    url: '/canvass'
    abstract: true
    templateUrl: 'templates/menu.html'
  ).state("canvass.home",
    cache: false
    url: "/home"
    views:
      menuContent:
        templateUrl: "templates/home.html"
        controller: 'HomeCtrl'
    resolve: HomeCtrl.resolve
  ).state("canvass.login",
    cache: false
    url: "/login"
    views:
      menuContent:
        templateUrl: "templates/login.html"
        controller: 'LoginCtrl'
  ).state("canvass.routes",
    cache: false
    url: "/routes"
    views:
      menuContent:
        templateUrl: "templates/routes.html"
        controller: 'RoutesCtrl'
    resolve: RoutesCtrl.resolve

  ).state("canvass.surveyResponseaction",
    cache: false
    url: "/surveyResponse/:id/:action"
    views:
      menuContent:
        templateUrl: "templates/surveyResponse.html"
        controller: 'SurveyResponseCtrl'
    resolve: SurveyResponseCtrl.resolve

  ).state("canvass.surveyResponseid",
    cache: false
    url: "/surveyResponse/:id"
    views:
      menuContent:
        templateUrl: "templates/surveyResponse.html"
        controller: 'SurveyResponseCtrl'
    resolve: SurveyResponseCtrl.resolve

  ).state("canvass.surveyResponse",
    cache: false
    url: "/surveyResponse"
    views:
      menuContent:
        templateUrl: "templates/surveyResponse.html"
        controller: 'SurveyResponseCtrl'
    resolve: SurveyResponseCtrl.resolve

  ).state("canvass.homes",
    cache: false
    url: "/homes"
    views:
      menuContent:
        templateUrl: "templates/homes.html"
        controller: 'HomesCtrl'
    resolve: HomesCtrl.resolve

  ).state("canvass.myLeads",
    cache: false
    url: "/myLeads"
    views:
      menuContent:
        templateUrl: "templates/myLeads.html"
        controller: 'MyLeadsCtrl'
    resolve: MyLeadsCtrl.resolve

  ).state("canvass.campaigns",
    cache: false
    url: "/campaigns"
    views:
      menuContent:
        templateUrl: "templates/campaigns.html"
        controller: 'CampaignsCtrl'
    resolve: CampaignsCtrl.resolve

  ).state("canvass.teams",
    cache: false
    url: "/teams"
    views:
      menuContent:
        templateUrl: "templates/teams.html"
        controller: 'TeamsCtrl'
    resolve: TeamsCtrl.resolve

  ).state("canvass.about",
    cache: false
    url: "/about"
    views:
      menuContent:
        templateUrl: "templates/about.html"
        controller: 'AboutCtrl'

  ).state("canvass.estimates",
    cache: false
    url: "/estimates/:home"
    views:
      menuContent:
        templateUrl: "templates/estimates.html"
        controller: 'EstimatesCtrl'
    resolve: EstimatesCtrl.resolve
  ).state("canvass.estimate",
    cache: false
    url: "/estimate/:home/:index"
    views:
      menuContent:
        templateUrl: "templates/estimate.html"
        controller: 'EstimateCtrl'
    resolve: EstimateCtrl.resolve
  )


  # if none of the above states are matched, use this as the fallback
  $urlRouterProvider.otherwise "/canvass/home"


  return


app.run ($ionicPlatform
  , $rootScope
  , $state
  , $window
  , $interval
  , $timeout
  , $http
  , $log
  , Auth
  , Util
  , GeoLoc
  , Customer
  , SurveyResponse
  , Survey
  , Route
  , Config
  , Maps
) ->

  $rootScope.$on 'surveyChanged', (s)->
    window.location.reload()

  $rootScope.$on '$routeChangeError', (e)->
    $log.log('e', e)

  $rootScope.$on '$stateChangeError', ( event,
                                        toState,
                                        toParams,
                                        fromState,
                                        fromParams,
                                        error )->
    $log.log('state change error', error)

  $ionicPlatform.ready ->
    if $window.cordova?.plugins?.Keyboard
      cordova.plugins.Keyboard.hideKeyboardAccessoryBar true

    StatusBar.styleDefault()  if $window.StatusBar

  #GeoLoc.watchUserPosition()

  $rootScope.$on '$locationChangeStart', (e, newurl, oldurl)->
    $rootScope.isLoggedIn = Auth.isLoggedIn()
    #$log.log 'logged in', loggedin
    if !$rootScope.isLoggedIn
      if newurl.indexOf("login") > -1
        # already going to login, don't do anything
      else
        e.preventDefault()
        $log.log 'redirecting to login'
        $state.go 'canvass.login'

        return

    # if $rootScope.online
      # check for a new spa version deployed
      # if so, reload the whole app.

      # this has not been reliable.
      # need to come up with a different caching strategy.
      # perhaps storing current version in the database instead.

      # if window.canvassApp.environment != 'development'
      #   $http.get('environment.json').success (env)->
      #     equal = env.timestamp == canvassApp.timestamp
      #     if !equal
      #       e.preventDefault()
      #       $window.location.reload()


  $rootScope.goBack = ()->
    $window.history.back()


  onUp = Util.debounce () ->
    $log.log 'onup'
    Customer.sync()
    Auth.sync()
    Survey.sync()

    SurveyResponse.sync(true)
    Route.sync()

  , 1000 * 30

  onDown = ->
    #$log.log 'ondown'
    $rootScope.online = false

  Offline.on 'confirmed-up', ()->
    $rootScope.online = true
    onUp()


  Offline.on 'confirmed-down', onDown

  return
