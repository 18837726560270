AtticInsulationController = ($scope)->

  if (!$scope.model.additionalInsulations)
    $scope.model.additionalInsulations = []

  $scope.types = [
    "Blown Fiberglass",
    "Batt Insulation",
    "Blanket Fiberglass",
  ]

  $scope.rvalues = [
    'R13',
    'R14?',
  ]

  $scope.insulationTypes = [
    "Blanket Fiberglass Crawl Space Insulation (R30)",
    "Blanket Fiberglass Crawl Space Insulation (R38)",
    "10 mil Poly Laid on Crawl Floor",
    "Drill and Fill Exterior Walls and Floor",
    "Drill and Fill Cathedral or Garage Ceilings",
    "Foil Radiant Barrier",
  ]

  $scope.addInsulation = ()->
    $scope.model.additionalInsulations.push
      location: ''
      type: ''

  $scope.removeAdditionalInsulation = (e, index)->
    e.preventDefault()
    $scope.model.additionalInsulations.splice(index,1)


AtticInsulationDirective = ()->
  restrict: 'E'
  templateUrl: 'directives/atticInsulation/index.html'
  scope:
    model: '='
  controller: AtticInsulationController

app.directive 'atticInsulation', AtticInsulationDirective
