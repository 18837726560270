'use strict'
app.factory "Online", ($q
  , $interval
  , Offline
  , Now
) ->

  service = {}
  service.confirmed = false

  Offline.on 'confirmed-up', ()->
    service.confirmed = true
    service.state = 'up'
  Offline.on 'confirmed-down', ()->
    service.state = 'down'
    service.confirmed = true

  Offline.check()
  
  service.confirmedState = ()->
    deferred = $q.defer()

    if service.confirmed
      deferred.resolve service.state
    else

      timer = $interval ()->
        if service.confirmed
          $interval.cancel timer
          deferred.resolve service.state
      ,100

    return deferred.promise

  return service



  
    

