'use strict'
app.factory "Survey", ($rootScope
  , $q
  , $log
  , Api
  , LocalStorage
  , Customer
  , Online
  , Util
  , Now
) ->
  service = Api('survey')
  service.mySurveys = []
  sync = ()->
    $log.log 'syncing surveys +++++++++++++++++++'
    service.getMySurveys().then( (res)->
      #$log.log 'successfully synced surveys', res
    ).catch( (err)->
      $log.error 'error syncing survey', err
      $log.log 'will try again later...'
    )

  service.sync = Util.debounce sync, 60000, true

  setDefaults = (survey)->

    # default value here, because survey.config is not part of the schema
    survey.config.leadConfirmEmailSubject = survey.config
      .leadConfirmEmailSubject || 'appointment confirmation'

    survey.config.leadConfirmEmailDateFormat = survey.config
      .leadConfirmEmailDateFormat || "dddd, MMMM Do YYYY, h:mm a"

    survey.config.leadConfirmEmailTemplate = survey.config
      .leadConfirmEmailTemplate || "Dear {{lead.name}},"

    survey.config.sendLeadConfirmEmails = survey.config
      .sendLeadConfirmEmails || false

    survey.config.objections = survey.config.objections || [
      selected:false
      title:'Not enough money'
    ,
      selected:false
      title:'No time for the pitch'
    ,
      selected:false
      title:'No time for the estimate'
    ,
      selected:false
      title:'All work is completed'
    ,
      selected:false
      title:'Both homeowners not available for estimate'
    ,
      selected:false
      title:'Not Interested'
      ]

    survey.config.leadTypes = survey.config.leadTypes || [
      selected:false
      title:'Windows'
      detail: ''
    ,
      selected:false
      title:'Siding'
      detail: ''
    ,
      selected:false
      title:'Roofing'
      detail: ''
    ,
      selected:false
      title:'Gutters'
      detail: ''
    ,
      selected:false
      title:'Trim'
      detail: ''
    ,
      selected:false
      title:'Insulation'
      detail: ''
    ,
      selected:false
      title:'Door'
      detail: ''
    ,
      selected:false
      title:'Other'
      detail: ''
      ]

    survey.type = survey.type || 'simplev2'


  setCurrent = (survey)->
    # will only be flagged as changed after it's first set
    changed = service.currentSurvey && service.currentSurvey?._id != survey?._id
    if survey
      LocalStorage['currentSurvey'] = angular.toJson(survey)
      service.currentSurvey = survey

      service.mySurveys.forEach (s)->
        # reset other surveys
        if s._id != survey?._id
          s.active = false # reset others
        else
          s.active = true

    else
      LocalStorage['currentSurvey'] = null
      service.currentSurvey = null
      service.mySurveys.forEach (s)->
        s.active = false


    LocalStorage.mySurveys = angular.toJson(service.mySurveys)
    if changed
      $rootScope.$broadcast 'surveyChanged', survey


  getMySurveys = ()->
    deferred = $q.defer()
    if !$rootScope.currentUser
      deferred.resolve []
      return deferred.promise


    cacheExpiry =  5000 # 5 seconds.
    now = Now().getTime()
    service.cachedTimestamp = service.cachedTimestamp || 0
    expirytime = service.cachedTimestamp + cacheExpiry
    cacheExpired = now > expirytime
    #$log.log 'cache', cacheExpiry, now, expirytime

    if !cacheExpired && service.mySurveys.length
      $log.log 'using cached surveys'
      deferred.resolve service.mySurveys
      return deferred.promise


    Online.confirmedState().then (state)->

      if (state == 'down')
        s = LocalStorage.currentSurvey

        if !s
          setCurrent null
          deferred.resolve service.mySurveys
          return deferred.promise
        else
          try
            survey = JSON.parse(s)
          catch e
            # old value may have been id rather than object.
            survey =
              '_id': s
              active: true

        try
          surveys = JSON.parse LocalStorage.mySurveys
        catch e
          $log.error 'cant parse localstorage mysurveys', e

        if surveys
          service.mySurveys = surveys

        if survey
          setCurrent survey

        deferred.resolve service.mySurveys
        return deferred.promise

      Customer.getCurrentCustomers().then (customers)->
        $log.log 'got customers', customers

        if !customers?.length
          service.mySurveys = []
          service.currentSurvey = null
          deferred.resolve service.mySurveys
          return


        $log.log 'geting surveys from server'
        ids = customers.map((c) -> c._id.toString())
        params =
          customer:
            $in: ids


        options =
          sort:
            dateUpdated: 'desc'

        if localStorage.options
          options = Object.assign options, JSON.parse localStorage.options


        service.some(params, undefined ,options)
        .catch (err)->
          $log.error 'service.some error getting surveys', err, params
          deferred.reject err
        .then (surveys)->

          # keep the current survey selected if possible
          if surveys?.length
            # ensure defaults are set
            surveys.forEach setDefaults

            # new surveys
            service.mySurveys = surveys
            if !service.currentSurvey
              s = LocalStorage.currentSurvey
              if s
                try
                  survey = JSON.parse(s)
                catch e
                  # old value may have been id rather than object.
                  survey =
                    '_id': s
                    active: true
              else
                # s = null
                # survey = null
            else
              survey = service.currentSurvey

            if survey

              # ensure defaults
              setDefaults survey

              surveys.forEach (s)->
                if s._id == survey._id
                  #update the 'current' survey
                  setCurrent s

            activeSurvey = surveys.find (s)->
              s.active

            # no current survey. select the first one available
            if (!activeSurvey && surveys.length)
              surveys[0].active = true
              setCurrent surveys[0]

            LocalStorage.mySurveys = angular.toJson service.mySurveys

            service.cachedTimestamp = Now().getTime()
            #$log.log 'cachedTimestamp', service.cachedTimestamp
            deferred.resolve service.mySurveys

          else
            deferred.resolve service.mySurveys || []


    return deferred.promise


  service.getMySurveys = getMySurveys

  service.setCurrent = setCurrent

  service.getById = (id)->
    service.mySurveys.find (s)->
      s._id == id

  service.getMySurvey = ()->
    deferred = $q.defer()
    if service.currentSurvey
      deferred.resolve service.currentSurvey
    else
      Online.confirmedState().then (state)->
        getMySurveys().then (surveys)->
          deferred.resolve service.currentSurvey
        .catch (err)->
          $log.error err
          deferred.resolve []

    return deferred.promise
  service
