EstimateCtrl = ($scope
, $rootScope
, estimate
, home
, $state
, SurveyResponse
)->
  
  $scope.est = estimate

  $scope.save = ()->
    estimate.dateUpdated = new Date()
    SurveyResponse.save home


  return

EstimateCtrl.resolve =
  home: (SurveyResponse, $stateParams)->
    SurveyResponse.one($stateParams.home)
  estimate: ($q, home, $stateParams)->

    estimate = home.estimates[$stateParams.index]
    if estimate is undefined
      estimate = {}
      home.estimates[$stateParams.index] = estimate
    
    if !estimate.attic
      estimate.attic = {}

    if !estimate.roofing
      estimate.roofing = {}

    $q.when estimate

app.controller 'EstimateCtrl', EstimateCtrl
