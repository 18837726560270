app.factory 'Util', ($rootScope
  , $log
  , User
  , Online
) ->

  lastlongitude = 0
  lastlatitude = 0
  lastPositionUpdate = 0

  isArray = Array.isArray ||
    ( value ) -> return {}.toString.call( value ) is '[object Array]'

  roundUp: (n, precision)->
    precision * Math.ceil(n / precision)

  isNumber: (n)->
    !isNaN(parseFloat(n)) && isFinite(n)

  typeIsArray : isArray

  debounce: (func, threshold, execAsap) ->
    timeout = null
    (args...) ->
      obj = this
      delayed = ->
        func.apply(obj, args) unless execAsap
        timeout = null
      if timeout
        clearTimeout(timeout)
      else if (execAsap)
        func.apply(obj, args)
      timeout = setTimeout delayed, threshold || 100


  # Most throttles are actually just delays
  # This will only call the function if it hasn't been triggered in (delay)ms
  throttle: (fn, delay) ->
    return fn if delay is 0
    timer = false
    return ->
      return if timer
      timer = true
      setTimeout (-> timer = false), delay unless delay is -1
      fn arguments...

  formatLeadType: (input)->

    # note, this is copied from the leadTypeFilter for front-end code...
    # need to plan on how to share services better...
    if !input || !(isArray(input))
      # old version
      input = input || {}
      out = ''
      for k,v of input
        if v
          isDetail = k.indexOf('Detail') != -1
          if !isDetail
            out += ' - ' + k + '\n'
          if isDetail
            out += '    ' + v + '\n'
      if out.length == 0
        out = ' - Not specified'
      else
        #remove trailing \n
        out = out.substring(0,out.length-1)

      return out

    # new version
    if input.length == 0
      return ' - Not specified'

    out = ''
    for type in input
      if type.selected
        out += '- ' + type.title
        if type.detail
          out += ': ' + type.detail
        out += "\n"

    #remove trailing \n
    return out.trim()

  updateCurrentUserPosition: ()->
    now = Date.now()
    # don't update position to server too often...
    return if (now - lastPositionUpdate) < 60000
    $log.log 'updateuserposition'

    user = $rootScope.currentUser
    if !user
      $log.log 'not logged in'
      return

    if !user.position || !user.position.coords
      $log.log 'no position for the user'
      return

    longEqual = lastlongitude == user.position.coords.longitude
    latEqual = lastlatitude == user.position.coords.latitude

    if longEqual && latEqual
      $log.log 'user not moved'
      return

    Online.confirmedState().then (state)->
      # just skip this if we're offline
      # we'll updated it as soon as we're back online
      return if state != 'up'
      update =
        id: user._id
        position: user.position

      #$log.log 'submitting updated user position', update
      User.update update
      , (user)->
        #$log.log 'user saved', user
        lastlongitude = user.position.coords.longitude
        lastlatitude = user.position.coords.latitude
        lastPositionUpdate = Date.now()

      , (err)->
        $log.error 'error saving user', err

  colorFromStatus: (r)->
    if r?.questions?.gotLead
      return 'green'
    else if r?.doNotCall
      return 'black'
    else if r?.questions?.doorAnswered
      return 'red'
    else return 'yellow' # no answer

  posAreEqual: (a, b)->
    trim = (v)->
      return (+v).toFixed(5)

    eq = (key, a, b)->
      return trim(a[key]) == trim(b[key])

    eq('longitude', a.coords, b.coords) && eq('latitude', a.coords, b.coords)

  waitForGoogle: () ->
    new Promise (resolve, reject)->
      waitForFoo = ()->
        if google?.maps
          return resolve(google.maps)
        setTimeout(waitForFoo, 100)

      waitForFoo()