'use strict'

app.controller 'Simplev2SurveyCtrl', ( $scope
  , $rootScope
  , $element
  , $log
  , $timeout
  , GeoLoc
  , LocalStorage
  , SurveyResponse
  , Util
) ->
  onAdminForm = undefined

  $scope.isDirty = (val) ->
    tv = typeof (val)
    tv isnt "undefined"

  $scope.openDate = (e) ->
    e.preventDefault()
    e.stopPropagation()
    $scope.dateOpened = true
    return

  $scope.doorAnswered = (val)->
    #$log.log "doorAnswered"
    if $scope.footer
      $scope.footer.display = false
    $scope.response.questions.doorAnswered = val
    $scope.response.questions.gotLead = false
    return  if $scope.onAdminForm
    delete $scope.response.questions.finishedPitch
    return

  $scope.finishedPitch = (val)->

    if $scope.footer
      $scope.footer.display = false
    $scope.response.questions.finishedPitch = val
    return  if $scope.onAdminForm
    delete $scope.response.questions.gotLead
    delete $scope.response.questions.homeowner
    return

  $scope.gotLead = (val)->
    #$log.log "gotLead"
    if $scope.footer
      $scope.footer.display = false
    $scope.response.questions.doorAnswered = true
    $scope.response.questions.finishedPitch = true
    $scope.response.questions.homeowner = true
    $scope.response.questions.gotLead = val
    return  if $scope.onAdminForm
    if !val
      resetObjections()

    if $scope.response.questions.lead?
      $scope.response.questions.lead
        .appointmentDate = (new Date()).setMinutes(0)

    return null

  resetObjections = ()->
    #$log.log 'reset objections'
    $scope.response.questions.objections.forEach (o)->
      o.selected = false

  $scope.homeowner = (val)->
    #$log.log 'homeowner'
    $scope.response.questions.homeowner = val
    delete $scope.response.questions.contacttype
    resetObjections()


  $scope.setTitle = (val)->
    if $scope.response.questions.lead.title == val
      $scope.response.questions.lead.title = ''
    else
      $scope.response.questions.lead.title = val
    
  $scope.saveAttitude = ()->
    LocalStorage.attitude = $scope.response.questions.attitude

  if !$scope.response
    # survey admin form only
    return

  if !$scope.response.questions
    $scope.response.questions = {}

  if !$scope.response.questions.objections
    $scope.response.questions.objections = $scope.response
      .survey.config.objections

  if !$scope.response.questions.lead
    $scope.response.questions.lead = {}

  if !$scope.response.questions.lead.types
    $scope.response.questions.lead.types = $scope.response
      .survey.config.leadTypes

  updateAddress = Util.debounce( (address)->

    if !address
      $log.log 'update address invalid address'
      return

    $timeout ()->

      newAddress =
        "#{address.streetNumber} #{address.streetName}, #{address.city}\
        , #{address.state} #{address.zip}, #{address.country}"

      if $scope.response.questions.lead.address == newAddress
        #$log.log 'unchanged address'
        return

      $scope.response.questions.lead.address = newAddress

      $rootScope.$broadcast 'addressChanged'

  , 2000)
  
  $scope.$watch ()->
    return JSON.stringify $scope.response.questions.lead.addressDetail
  , (nv, ov)->
    if nv != ov
      updateAddress($scope.response.questions.lead.addressDetail)

  $scope.onTextAreaFocus = (e)->
    # $log.log('focus textarea element', e.target)
    el = e.target
    el.style.height = '15em'
    scrollIntoView(el)

  onFocusScrollTop = 0

  scrollIntoView = (el)->
    return if !el

    # scroll to the label above the input
    if el.previousSibling
      sibling = el.previousSibling
      if sibling.scrollIntoView
        el = sibling
      else
        # text nodes do not have scrollIntoView
        # just let it scroll to itself

    el.scrollIntoView()

    # el?.parentElement?.scrollTo(0,0);
    # el?.scrollTo(0,0)

  $scope.onTextAreaBlur = (e)->
    # $log.log('blur textarea element', e.target)
    el = e.target
    el.style.height = 'unset'
    # resetScrollPos()


  $scope.onTextInputFocus = (e)->
    # $log.log('focus txt input element', e.target)
    el = e.target
    scrollIntoView(el)

  $scope.onTextInputBlur = (e)->
    # resetScrollPos()
