app.factory 'Auth', ($rootScope
  , $log
  , Session
  , User
  , LocalStorage
  , Messages
  , Util
  , Online
) ->

  $log.log 'loading auth'

  service = {}

  #stay logged in if we can
  if !$rootScope.currentUser && LocalStorage.currentUser
    try
      $rootScope.currentUser = JSON.parse LocalStorage.currentUser
    catch err
      $log.error 'error parsing user loading auth', err, LocalStorage
      $rootScope.currentUser = null

  service.lastEmail = LocalStorage.lastEmail

  service.persistCurrentUserToLocalStorage = ()->
    LocalStorage.currentUser = angular.toJson $rootScope.currentUser

  sync = ()->
    $log.log 'syncing user +++++++++++++++++++'
    if !$rootScope.currentUser
      $log.log "can't sync - no current user"
      return
    params =
      id: $rootScope.currentUser._id
    User.one params
    
    , (res)->
      setUser res.user

    , (err)->
      $log.error 'error syncing user', err


  service.sync = Util.debounce sync, 60000, true

  setUser = (user)->
    $rootScope.currentUser = JSON.parse angular.toJson user
    $rootScope.isLoggedIn = true
    service.persistCurrentUserToLocalStorage()
    LocalStorage.lastEmail = user.email
    service.lastEmail = user.email

  clearUser = ()->
    Messages.leaveAll() if Messages
    $rootScope.currentUser = null
    $rootScope.isLoggedIn = false
    LocalStorage.currentUser = null
    # intentionally leaving the 'last' email to allow prefill of login form
    $rootScope.$broadcast 'logout'
    

  removeUnlicensedFeatures = (authorizedFeatures)->
    # for now, do nothing.
    authorizedFeatures


  service.getCurrentUser = ()->
    deferred = $q.defer()
    if $rootScope.currentUser
      deferred.resolve $rootScope.currentUser
      return deferred.promise

    if LocalStorage.currentUser
      try
        $rootScope.currentUser = JSON.parse LocalStorage.currentUser
      catch err
        $log.error 'error parsing user getcurrentUser', err, LocalStorage
    
    if $rootScope.currentUser
      deferred.resolve $rootScope.currentUser
      return deferred.promise

    deferred.reject 'unable to get current user'

    return deferred.promise


  service.login = (user, callback) ->
    cb = callback or angular.noop
    Session.save(
      email: user.email
      password: user.password
    , (user) ->
      setUser user
    ).$promise

  
  service.logout = (callback) ->
    cb = callback or angular.noop
    Session.delete( ()->
      clearUser()
      cb()
    , (err) ->
      cb err
    ).$promise

  
  service.createUser = (user, callback) ->
    cb = callback or angular.noop
    User.save(user, (user) ->
      setUser user
      cb user
    , (err) ->
      cb err
    ).$promise

  
  service.changePassword = (oldPassword, newPassword, callback) ->
    cb = callback or angular.noop
    User.update(
      oldPassword: oldPassword
      newPassword: newPassword
    , (user) ->
      cb user
    , (err) ->
      cb err
    ).$promise

  service.currentUser = ->
    $rootScope.currentUser
  
  service.isLoggedIn = ->
    user = $rootScope.currentUser
    !!user

  service.isAuthorizedFor = (activity)->
    # these may be able to be set in the closure...
    adminActivities = ['users','adminservices']
    staffActivities = ['reports','tickets']

    user = $rootScope.currentUser
    return false if !user

    authorizedActivities =[]
    if user.role == 'admin'
      Array::push.apply authorizedActivities, adminActivities

    if user.role in ['staff','admin']
      Array::push.apply authorizedActivities, staffActivities

    # check if the activity is in the list of authorized ones.
    activity in removeUnlicensedFeatures authorizedActivities

  service.setUser = setUser

  service.updateCurrentUserGPS = ()->

    $log.log 'updateCurrentUserGPS'

    user = $rootScope.currentUser
    if !user
      $log.log 'not logged in'
      return


    Online.confirmedState().then (state)->
      # just skip this if we're offline
      # we'll updated it as soon as we're back online
      return if state != 'up'
      update =
        id: user._id
        testGps: user.testGps

      #$log.log 'submitting updated user ', update
      User.update update
      , (user)->
        setUser user
      , (err)->
        $log.error 'error saving user', err

  return service
