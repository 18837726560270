app.filter 'leadType', ()->
  (input)->
    input = input || {}
    out = ''
    for k,v of input
      if v
        isDetail = k.indexOf('Detail') != -1
        if !isDetail
          out += ' - ' + k + '\n'
        if isDetail
          out += '    ' + v + '\n'

    if out.length == 0
      out = ' - Not specified'
    else
      #remove trailing \n
      out = out.substring(0,out.length-1)

    return out
    
