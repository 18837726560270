# coffeelint: disable=max_line_length
app.factory 'GeoLoc', ( $q
  , $rootScope
  , $log
  , $window
  , $interval
  , $timeout
  , Util
  , Auth
  , Customer
  , Online
)->

  service = {}

  onError = () ->
    $rootScope.$emit 'gpsError', service.gpsError

  options = null

  getOptions = ()->
    usergeo = $rootScope.currentUser?.geoloc
    if !usergeo
      usergeo = {}
    customergeo = Customer.currentCustomer?.geoloc
    if !customergeo
      customergeo = {}

    geo  = angular.extend {},customergeo,usergeo

    highAccuracy = geo?.highAccuracy

    if highAccuracy is null || highAccuracy is undefined
      highAccuracy = true

    timeout = geo?.timeout * 1000
    maxAge = geo?.maximumAge * 1000
    options =
      enableHighAccuracy: highAccuracy
      timeout: timeout || 20000
      maximumAge: maxAge  || 120000

    #$log.log 'geoloc options', options
    return options

  getPositionError = null
  routePosition = 0
  # choose a random 'offset' for starting point
  routeOffset = Math.random() * 60


  getRoutePosition = ()->
    now = new Date()
    minute = now.getMinutes()
    # 0-59
    #
    if (minute + routeOffset > 60)
      minute = (minute + routeOffset) - 60
    else
      minute = minute + routeOffset

    seconds = now.getSeconds()
    time = minute + (seconds / 60)

    fractionThroughRoute = time / 60

    # if route takes 60 minutes

    routePosition = Math.floor (fakeRoute.length * fractionThroughRoute)

    # but I want it to take 30 minutes
    # routePosition *= 2

    # ensure it is an 'even' number
    if !(routePosition % 2 == 0)
      routePosition += 1

    # but I may need to 'wrap around' now
    if routePosition >= fakeRoute.length
      routePosition = routePosition - fakeRoute.length

    if routePosition >= fakeRoute.length
      #rounding error on 00 minute??
      routePosition = 0


    return routePosition


  fakePos = ()->

    routePosition = getRoutePosition()
    #routePosition = 0

    pos =
      coords:
        longitude: fakeRoute[routePosition++]
        latitude: fakeRoute[routePosition++]
        accuracy: 3 + (Math.floor(Math.random() * 20))
      timestamp: Date.now()

    #logstr = "p # #{routePosition} of #{fakeRoute.length} from fakePos"
    #console.log logstr , pos
    return pos

  fakeRoute = [
    -124.04562,
    49.63286,
    -124.04559,
    49.6329000,
    -124.04520,
    49.63324,
    -124.04503,
    49.6331900,
    -124.04505,
    49.63311,
    -124.04506,
    49.6330700,
    -124.04506,
    49.6330100,
    -124.04506,
    49.63295,
    -124.04505,
    49.6329200,
    -124.04504,
    49.6328800,
    -124.04499,
    49.6328,
    -124.04493,
    49.6326900,
    -124.04487,
    49.6326000,
    -124.04483
    49.6325400,
    -124.04477,
    49.6324700,
    -124.04459,
    49.6323200,
    -124.04477,
    49.6324700,
    -124.04483,
    49.6325400,
    -124.04487,
    49.6326000,
    -124.04493,
    49.6326900,
    -124.04499,
    49.6328,
    -124.04504,
    49.6328800,
    -124.04505,
    49.6329200,
    -124.04506,
    49.63295,
    -124.04506,
    49.6330100,
    -124.04506,
    49.6330700,
    -124.04505,
    49.63311,
    -124.04503,
    49.6331900,
    -124.04520,
    49.63324,
    -124.04529,
    49.6332600,
    -124.04542,
    49.63331,
    -124.04568,
    49.6334100,
    -124.04572,
    49.6334300,
    -124.04574,
    49.6334400,
    -124.04576,
    49.6334600,
    -124.04577,
    49.63347,
    -124.04579,
    49.63349,
    -124.04579,
    49.63351,
    -124.04580,
    49.63354,
    -124.04579,
    49.6335700,
    -124.04576,
    49.6336800,
    -124.04573,
    49.6338600,
    -124.04567,
    49.6341100,
    -124.04565,
    49.63414,
    -124.04564,
    49.63416,
    -124.04562,
    49.6341700,
    -124.04559,
    49.63418,
    -124.0455,
    49.63421,
    -124.04551,
    49.63425
    -124.04554,
    49.63434,
    -124.04566,
    49.6345700,
    -124.04573,
    49.6346900,
    -124.04603,
    49.6349300,
    -124.04624,
    49.63506,
    -124.0464,
    49.63517,
    -124.04651,
    49.63526,
    -124.04656,
    49.6353200,
    -124.04661,
    49.6354000,
    -124.04664,
    49.6354500,
    -124.04667,
    49.6355200,
    -124.04669,
    49.63559,
    -124.04670,
    49.63564,
    -124.04669,
    49.63571,
    -124.04667,
    49.6357600,
    -124.04665,
    49.6358,
    -124.04662,
    49.63584,
    -124.04649,
    49.6359400,
    -124.04634,
    49.63582,
    -124.04626,
    49.6357600,
    -124.04615,
    49.6356900,
    -124.04608,
    49.63566,
    -124.04598,
    49.6356300,
    -124.04588,
    49.6356300,
    -124.04575,
    49.6356300,
    -124.04558,
    49.6356500,
    -124.04549,
    49.63568,
    -124.04536,
    49.6357200,
    -124.04528,
    49.63577,
    -124.04515,
    49.63586,
    -124.04505,
    49.63598,
    -124.04490,
    49.6361700,
    -124.04486,
    49.6362100,
    -124.04483,
    49.6362300,
    -124.04480,
    49.6362500,
    -124.04476,
    49.6362600,
    -124.04473,
    49.6362600,
    -124.04469,
    49.6362600,
    -124.04464,
    49.6362500,
    -124.04460,
    49.63624,
    -124.04456,
    49.6362300,
    -124.04452,
    49.6362,
    -124.04449,
    49.6361700,
    -124.04440,
    49.6360100,
    -124.04428,
    49.6357900,
    -124.04425,
    49.6357400,
    -124.04421,
    49.6357000,
    -124.0442,
    49.6356900,
    -124.04417,
    49.6356700,
    -124.04414,
    49.6356500,
    -124.04410,
    49.6356300,
    -124.04406,
    49.6356100,
    -124.04397,
    49.63559,
    -124.04351,
    49.6355200,
    -124.04339,
    49.6355000,
    -124.04302,
    49.6354500,
    -124.04297,
    49.63544,
    -124.04292,
    49.63544,
    -124.04287,
    49.6354500,
    -124.04283,
    49.6354500,
    -124.04275,
    49.63546,
    -124.04272,
    49.6354700,
    -124.04269,
    49.63548,
    -124.04234,
    49.6355800,
    -124.04231,
    49.6348400,
    -124.04234,
    49.6344000,
    -124.04231,
    49.6342400,
    -124.04234,
    49.6344000,
    -124.04231,
    49.6348400,
    -124.04115,
    49.6348500,
    -124.04110,
    49.6345800,
    -124.04106,
    49.6343800,
    -124.04106,
    49.6343500,
    -124.04104,
    49.6343100,
    -124.04095,
    49.63412,
    -124.04093,
    49.6341000,
    -124.04085,
    49.6340000,
    -124.0408,
    49.6339500,
    -124.04079,
    49.6339300,
    -124.04078,
    49.6339100,
    -124.04076,
    49.63387,
    -124.04075,
    49.6338600,
    -124.04071,
    49.6334800,
    -124.04075,
    49.6338600,
    -124.04076,
    49.63387,
    -124.04078,
    49.6339100,
    -124.04079,
    49.6339300,
    -124.0408,
    49.6339500,
    -124.04085,
    49.6340000,
    -124.04093,
    49.6341000,
    -124.04095,
    49.63412,
    -124.04104,
    49.6343100,
    -124.04106,
    49.6343500,
    -124.04106,
    49.6343800,
    -124.04110,
    49.6345800,
    -124.04115,
    49.6348500,
    -124.04046,
    49.63486,
    -124.04037,
    49.63486,
    -124.04031,
    49.6348500,
    -124.04025,
    49.6348400,
    -124.04019,
    49.63483,
    -124.04013,
    49.6348200,
    -124.04007,
    49.63479,
    -124.04004,
    49.63477,
    -124.03993,
    49.6346900,
    -124.03986,
    49.6345800,
    -124.03985,
    49.6345300,
    -124.03984,
    49.6344900,
    -124.03982,
    49.6342600,
    -124.03982,
    49.6341700,
    -124.03982,
    49.6341500,
    -124.03981,
    49.63412,
    -124.03981,
    49.6341100,
    -124.03980,
    49.6341000,
    -124.03979,
    49.6340900,
    -124.03977,
    49.6340800,
    -124.03947,
    49.63403,
    -124.03977,
    49.6340800,
    -124.03979,
    49.6340900,
    -124.03980,
    49.6341000,
    -124.03981,
    49.6341100,
    -124.03981,
    49.63412,
    -124.03982,
    49.6341500,
    -124.03982,
    49.6341700,
    -124.03982,
    49.6342600,
    -124.03984,
    49.6344900,
    -124.03985,
    49.6345300,
    -124.03986,
    49.6345800,
    -124.03993,
    49.6346900,
    -124.04004,
    49.63477,
    -124.04007,
    49.63479,
    -124.04013,
    49.6348200,
    -124.04019,
    49.63483,
    -124.04025,
    49.6348400,
    -124.04031,
    49.6348500,
    -124.04037,
    49.63486,
    -124.04046,
    49.63486,
    -124.04115,
    49.6348500,
    -124.04231,
    49.6348400,
    -124.04234,
    49.6355800,
    -124.04204,
    49.6356300,
    -124.04173,
    49.63564,
    -124.04071,
    49.6356000,
    -124.03928,
    49.63559,
    -124.03835,
    49.6356700,
    -124.03831,
    49.63562,
    -124.03829,
    49.6356100,
    -124.03826,
    49.63559,
    -124.03821,
    49.635580,
    -124.0381,
    49.63557,
    -124.0379,
    49.635560,
    -124.0379,
    49.635560,
    -124.0379,
    49.635540,
    -124.0378,
    49.63553,
    -124.0378,
    49.635430,
    -124.0376,
    49.6353200
  ]

  gpsErrorMsg = "Unable to obtain device location.  \
  Please check that you have turned on your GPS location services \
  on the device, and that you have given permission for CanvassIQ \
  to access your location information - \
  you should see a prompt in your browser window. Error: "



  watch = null
  isWatchingPosition = ()->
    return !!watch

  clearWatchPosition = ()->
    if fakeWatch
      $window.clearInterval fakeWatch

    if watch
      navigator.geolocation.clearWatchPosition watch

    fakeWatch = null
    watch = null

  fakeWatch = null

  fakeWatchPosition = (cb)->
    if fakeWatch
      return
    # fake a 5 second delay
    fakeWatch = $window.setInterval ()->
      cb null, fakePos()
    , 5000

  fakeGetPosition = (cb)->
    $timeout ()->
      cb null, fakePos()
    ,1

  centerOfUSA =
    coords:
      latitude: 37.09024
      longitude: -95.712891
    accuracy: 10

  service.centerOfUSA = centerOfUSA


  getPosition = (cb)->
    if $rootScope.currentUser?.testGps
      fakeGetPosition cb
      return
    $log.log 'about to get current position'
    try
      navigator.geolocation.getCurrentPosition (pos)->
        pos =
          timestamp: pos.timestamp
          coords:
            latitude: pos.coords.latitude
            longitude: pos.coords.longitude
            accuracy: pos.coords.accuracy
            altitude: pos.coords.altitude
            altitudeAccuracy: pos.coords.altitudeAccuracy
            heading: pos.coords.heading
            speed: pos.coords.speed

        $log.log 'position obtained from device (pos)', pos
        if !pos.coords
          service.gpsError = gpsErrorMsg + ' invalid position'
          onError()
          cb service.gpsError, centerOfUSA
          return

        cb null, pos
      , (err)->
        #$log.log 'navigator getCurrentPosition error', err
        msg = err.message || "unknown error obtaining location. code:"+err.code
        service.gpsError = gpsErrorMsg + msg
        onError()
        cb service.gpsError, centerOfUSA
      , getOptions()
    catch ex
      # handle startup case of not getting position after user interaction.
      # just show their current position
      $log.log ex
      pos = $rootScope.currentUser?.position
      cb null, $rootScope.currentUser?.position


  validatePos = (pos)->
    if pos.coords
      #$log.log 'got current position (pos)', pos
      service.gpsError = ''
      onError()
    else
      $log.error 'error getting position', pos
      service.gpsError = gpsErrorMsg
      onError()

  gpsErr = (err)->
    service.gpsError = gpsErrorMsg + err
    onError()


  watchUserPosition = ()->
    $log.log 'watchUserPosition'
    if watch
      navigator.geolocation.clearwatch(watch)

    watch = navigator.geolocation.watchPosition (pos)->
      validatePos pos
      pos =
        timestamp: pos.timestamp
        coords:
          latitude: pos.coords.latitude
          longitude: pos.coords.longitude
          accuracy: pos.coords.accuracy
          altitude: pos.coords.altitude
          altitudeAccuracy: pos.coords.altitudeAccuracy
          heading: pos.coords.heading
          speed: pos.coords.speed

      updateUserPosition pos

    , gpsErr

    return watch

  updateUserPosition = Util.debounce (pos)->
    $rootScope.$broadcast 'positionUpdated', pos
    if $rootScope.currentUser
      $rootScope.currentUser.position = pos
      Auth.persistCurrentUserToLocalStorage()
      console.log 'saving current user position'
      Util.updateCurrentUserPosition()

  , 1000 * 30, true # execute immediately

  service.getPositionFromAddress = (address)->
    deferred = $q.defer()
    Online.confirmedState().then (state)->
      if !(state == 'up') || !google.maps
        deferred.reject 'Unable to get position from address while offline'
        return deferred.promise

      geocoder = new google.maps.Geocoder()
      geocoder.geocode {address:address}, (results, status)->
        if status != google.maps.GeocoderStatus.OK
          deferred.reject 'geocoding failed due to: ' + status
          return
        if !results[0]
          deferred.reject 'geocoding failed to return a position'
          return
        loc = results[0].geometry.location
        pos =
          coords:
            longitude: loc.lng()
            latitude: loc.lat()
          timestamp: Date.now()

        deferred.resolve pos

    return deferred.promise

  service.getAddressFromPosition = (pos)->
    deferred = $q.defer()

    Online.confirmedState().then (state)->

      if !(state=='up') || !google.maps || !google.maps.Geocoder
        deferred.reject 'offline'
        return deferred.promise

      geocoder = new google.maps.Geocoder()
      latLng = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude)
      try
        geocoder.geocode {latLng: latLng}, (results, status)->
          if status != google.maps.GeocoderStatus.OK
            deferred.reject 'address unavailable: ' + status
            return
          if !results[0]
            deferred.reject 'geocoding failed to return an address'
            return

          filtered = results.filter (a)->a.types.includes('street_address')

          ###
          results may still be crap, as I've found in a townhouse complex near my place.
          we could display a set of addressed that the user could choose from but it's unclear when we should do that?
          maybe display the options under a button that the user can click on when the address doesn't make sense

          in this example, the first result is thousands of miles away from the correct second result.
          the first result has a noticably incorrect location

[
    {
        "address_components": [
            {
                "long_name": "15",
                "short_name": "15",
                "types": [
                    "street_number"
                ]
            },
            {
                "long_name": "Everstone Dr SW",
                "short_name": "Everstone Dr SW",
                "types": [
                    "route"
                ]
            },
            {
                "long_name": "Calgary",
                "short_name": "Calgary",
                "types": [
                    "locality",
                    "political"
                ]
            },
            {
                "long_name": "Capital Regional District",
                "short_name": "CRD",
                "types": [
                    "administrative_area_level_2",
                    "political"
                ]
            },
            {
                "long_name": "AB",
                "short_name": "AB",
                "types": [
                    "administrative_area_level_1",
                    "political"
                ]
            },
            {
                "long_name": "Canada",
                "short_name": "CA",
                "types": [
                    "country",
                    "political"
                ]
            },
            {
                "long_name": "T2Y 5B5",
                "short_name": "T2Y 5B5",
                "types": [
                    "postal_code"
                ]
            }
        ],
        "formatted_address": "15 Everstone Dr SW, Calgary, AB T2Y 5B5, Canada",
        "geometry": {
            "location": {
                "lat": 48.659639,
                "lng": -123.4137911
            },
            "location_type": "ROOFTOP",
            "viewport": {
                "south": 48.6582900197085,
                "west": -123.4151400802915,
                "north": 48.66098798029149,
                "east": -123.4124421197085
            }
        },
        "place_id": "ChIJuaJ6RkFnj1QRsjtD7f0EeqY",
        "plus_code": {
            "compound_code": "MH5P+VF Sidney, BC, Canada",
            "global_code": "84WRMH5P+VF"
        },
        "types": [
            "street_address"
        ]
    },
    {
        "address_components": [
            {
                "long_name": "2071",
                "short_name": "2071",
                "types": [
                    "street_number"
                ]
            },
            {
                "long_name": "Amelia Avenue",
                "short_name": "Amelia Ave",
                "types": [
                    "route"
                ]
            },
            {
                "long_name": "Sidney",
                "short_name": "Sidney",
                "types": [
                    "locality",
                    "political"
                ]
            },
            {
                "long_name": "Capital Regional District",
                "short_name": "CRD",
                "types": [
                    "administrative_area_level_2",
                    "political"
                ]
            },
            {
                "long_name": "British Columbia",
                "short_name": "BC",
                "types": [
                    "administrative_area_level_1",
                    "political"
                ]
            },
            {
                "long_name": "Canada",
                "short_name": "CA",
                "types": [
                    "country",
                    "political"
                ]
            },
            {
                "long_name": "V8L 3Z8",
                "short_name": "V8L 3Z8",
                "types": [
                    "postal_code"
                ]
            }
        ],
        "formatted_address": "2071 Amelia Ave, Sidney, BC V8L 3Z8, Canada",
        "geometry": {
            "location": {
                "lat": 48.6590999,
                "lng": -123.4138466
            },
            "location_type": "RANGE_INTERPOLATED",
            "viewport": {
                "south": 48.6577509197085,
                "west": -123.4151955802915,
                "north": 48.6604488802915,
                "east": -123.4124976197085
            }
        },
        "place_id": "EiMyMDcxIEFtZWxpYSBBdmUsIFNpZG5leSwgQkMsIENhbmFkYSIbEhkKFAoSCYlj7_7YZ49UERpJsc_gFAgPEJcQ",
        "types": [
            "street_address"
        ]
    }
]


          ###


          deferred.resolve parseAddress filtered[0]
      catch ex
        console.error ex
        deferred.reject 'error obtaiing geolocation'

    return deferred.promise


  getComponent = (type, components)->
    c = components.find (c)->
      return false if !c || !c.types || !c.types[0]
      c.types[0] == type

    c?.short_name || ''

  parseAddress = (gAddress)->

    comps = gAddress.address_components

    address =
      formattedAddress : gAddress.formatted_address
      addressDetail :
        streetNumber: (+(getComponent 'street_number', comps)) || ''
        streetName: getComponent 'route', comps
        city: getComponent 'locality', comps
        state: getComponent 'administrative_area_level_1', comps
        country: getComponent 'country', comps
        zip: getComponent 'postal_code', comps

    return address


  service.parseAddressFromFormatted = (a)->

    parts = a.split(',')
    parts = parts.map (p)->
      p.trim()

    if parts[2]
      statePostalParts = parts[2].split(' ')
      state = statePostalParts.shift()
      postal = statePostalParts.join(' ')
    else
      state = ''
      postal = ''

    streetParts = parts[0].split(' ')
    streetParts = streetParts.map (p)->
      p.trim()

    if Util.isNumber streetParts[0]
      streetNumber = streetParts.shift() || ''
    else
      streetNumber = ''

    streetName = streetParts.join(' ') || ''
    a =
      streetNumber: streetNumber
      streetName: streetName
      city: parts[1] || ''
      state: state || ''
      zip: postal || ''
      country: parts[3] || ''

    return a

  service.emptyAddressDetail = ()->
    a = angular.copy
      streetNumber: ""
      streetName: ""
      city: ""
      state: ""
      zip: ""
      country: ""

    return a

  service.getCurrentPosition = (mapPosition)->

    deferred = $q.defer()
    if mapPosition
      pos =
        coords:
          latitude: mapPosition.lat()
          longitude: mapPosition.lng()
        timestamp: Date.now()

      deferred.resolve pos
      return deferred.promise

    getPosition (err, pos)->
      if err
        deferred.reject err
        return

      updateUserPosition pos

      deferred.resolve pos


    return deferred.promise


  service.stopWatchingUserPosition = ()->
    clearWatchPosition()
  service.clearWatchPosition = clearWatchPosition
  service.isWatchingPosition = isWatchingPosition
  service.watchUserPosition = watchUserPosition

  return service

