'use strict'
app.factory 'Route', ($q
  , $rootScope
  , $log
  , $timeout
  , Api
  , LocalStorage
  , Online
) ->
  routeService = Api 'route'
  routeService.myRoutes = null

  if LocalStorage.myRoutes
    try
      routeService.myRoutes = JSON.parse(LocalStorage.myRoutes)
    catch e
      $log.error 'error parsing routes', e

  routeService.addHome = (home)->

    #ignore if no active routes.
    activeRoutes = routeService.myRoutes.filter (r)->
      r.active
    route = activeRoutes[0]
    if !route
      return

    Online.confirmedState().then (state)->
      # route.poly is only for display purposes,
      # and triggers an error when stringifying
      # because of circular references.
      delete route.poly
      delete route.line

      $log.log 'adding home to route', route
      # avoid duplicates, just in case
      foundIndex = route.homes.findIndex (h)->
        h = home._id
      if foundIndex == -1
        route.homes.unshift home._id
      else
        route.homes[foundIndex] = home._id



      
      route.dirty = true
      # we don't need to trigger the sync process
      # the system will do that once the home is saved
      # as this triggers the 'confirmed-up' event.
      # which ensures that eny dirty routes are saved.
      return


  saveRoute = (r)->
    $log.log 'saving route', r
    # set this to false now rather than
    # in the save complete event
    # to avoid race conditions
    r.dirty = false
    routeService.save(r,['canvassers', 'homes']).then (route)->
      $log.log 'route saved', route
      # the active flag is local only, not stored on the server
      # because it's for this user only.
      route.active = r.active
      index = routeService.myRoutes.findIndex (ar)->
        ar._id == route._id
      #replace
      if index < 0
        $log.log 'invalid routeid'
        return

      routeService.myRoutes[index] = route

      #persist to localstorage
      routeService.saveMyRoutes()

  syncRoute = (rIndex)->
    r = routeService.myRoutes[rIndex]
    if !r.dirty
      return true

    # we can't save routes
    # where the homes have not already been synced to the server
    # because we won't have a valid home ID
    # Just keep checking until all homes have valid ids
    invalid = r.homes.find (h)->
      h.indexOf('-') > -1

    if invalid

      $log.log 'invalid home id (unsynced), try again later'
      $timeout ()->
        # need to use index because route will be updated by
        # the response/home sync process
        syncRoute rIndex

      # need to give this enough time to avoid
      # issues with the dirty flag not being reset
      # _after_ saving, causing mongoose version errors
      , 4000
      return
      
    saveRoute r
    
    return

  routeService.sync = ()->
    routeService.getMyRoutes(true).then (routes)->
      routes.forEach (r)->
        routeIndex = routeService.myRoutes.findIndex (ar)->
          ar._id == r._id
        syncRoute routeIndex


  routeService.updateHome = (tmpid, h)->
    routeService.myRoutes.forEach (r)->
      index = r.homes.findIndex (h)->
        h == tmpid
      if index > -1
        # replace with valid id
        $log.log 'replacing id', tmpid

        r.homes[index] = h._id


  routeService.setMyRoutes = (routes)->
    if routes?.forEach
      # check for the 'active' flag in current set of routes
      if routeService.myRoutes
        routes.forEach (newR)->
          routeService.myRoutes.forEach (oldR)->
            if oldR._id == newR._id
              newR.active = oldR.active


      # if no active routes, but we have at least one route
      # make the first route the active route
      #
      activeRoute = routes.find (r)->
        r.active
      if (!activeRoute && routes.length)
        routes[0].active = true

      routeService.myRoutes = routes
      routeService.saveMyRoutes()

  routeService.saveMyRoutes = ()->
    if routeService.myRoutes?.forEach
      routeService.myRoutes.forEach (r)->
        delete r.line # artifact of drawing on screen
        delete r.poly

      #$log.log 'saving routes'
      LocalStorage.myRoutes = angular.toJson(routeService.myRoutes)

  routeService.getMyRoutes = (useCached)->
    deferred = $q.defer()
    Online.confirmedState().then (state)->

      if !$rootScope.currentUser
        deferred.resolve []
        return

      if useCached && routeService.myRoutes
        deferred.resolve routeService.myRoutes
        return

      if state == 'down'
        # use the cache if we're offline
        # regardless of 'useCached' parameter
        deferred.resolve routeService.myRoutes
        return

      params =
        canvassers:
          '$in' : [$rootScope.currentUser._id]

      routeService.some(params,'canvassers')
      .catch (err)->
        $log.error 'error getting routes', err
        deferred.reject err
        
      .then (routes)->
        routeService.setMyRoutes routes
        deferred.resolve routes
        

    return deferred.promise

  routeService
