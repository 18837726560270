app.controller 'LoginCtrl', ($scope,
  $ionicPopup,
  $state,
  $log,
  $rootScope,
  Auth,
  Customer,
  SurveyResponse,
  GeoLoc
)->

  $scope.email = Auth.lastEmail

  $scope.login = ()->
    Auth.login(
      email: this.email
      password: this.password
    ).then( (user)->
      # success, redirect to home page

      $state.go 'canvass.home'
      $rootScope.canvasserData = null
    ).then( ()->
      Customer.getCurrentCustomer()

    ).catch( (error)->
      $log.error 'login error', error

      $ionicPopup.alert
        title: 'Sign in error'
        template: error?.data?.message || 'unknown sign in error'
      
    )
            

  $scope.logout = ()->
    Auth.logout ()->

      SurveyResponse.reset()
      Customer.resetCurrentCustomer()
      GeoLoc.stopWatchingUserPosition()

      $ionicPopup.alert
        title: 'Signed out!'
        template: '<div>you have now signed out.</div>'

