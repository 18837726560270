'use strict'

app.directive 'cvSpinnumber', (Util, $log)->

  restrict: 'E'
  scope:
    ngModel: '='
  link: (scope, iElement, iAttrs)->
    scope.value = 0
    scope.increment = ()->
      if Util.isNumber scope.ngModel
        scope.ngModel++
      else
        scope.ngModel = 1

    scope.decrement = ()->
      if Util.isNumber scope.ngModel
        scope.ngModel--

  template:
    '<div class="input-group">
      <span class="input-group-btn">
        <button class="btn btn-default" type="button" ng-click="decrement()">
          <span class="glyphicon glyphicon glyphicon-chevron-down" ></span>
        </button>
      </span>

      <input type="text" class="form-control" ng-model="ngModel"
        required="true" />

      <span class="input-group-btn">
        <button class="btn btn-default" type="button" ng-click="increment()">
          <span class="glyphicon glyphicon glyphicon-chevron-up" ></span>
        </button>
      </span>
    </div>'

