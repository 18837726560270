MessagesCtrl = ($scope
  , $log
  , Messages
  , Customer
  , currentCustomer

)->
  $scope.messages = Messages
  $scope.help =
    isActive: false

  $scope.toggleHelp = ()->
    $scope.help.isActive = !$scope.help.isActive
    $log.log 'toggling help', $scope.help.isActive

  $scope.showUsers = ()->
    $log.log 'showusers'
    $scope.selectedChannel.requestUsers()


  if currentCustomer?.enableChat && Messages


    $scope.sendMessage = (channel)->
      channel.sendMessage()

    clearDirtyIndicator = (channel)->
      Messages.isDirty = false
      channel.dirtyIndicator = ''

    list = document.getElementById 'chatList'
    $scope.onChannelSelect = (channel)->
      $log.log 'channel', channel
      list.size = 1
      return if !channel

      $scope.selectedChannel = channel
      clearDirtyIndicator(channel)
      return false

    $scope.onInputFocus = (channel)->
      clearDirtyIndicator(channel)
    $scope.onMessagesFocus = (channel)->
      clearDirtyIndicator(channel)


    $scope.channels = Messages.channels

    ac = Messages.channels.find (c)->
      c._id == currentCustomer._id
    
    $scope.onChannelSelect ac
    $scope.chosen = ac

    $scope.openRoomList = ()->
      # don't open the list if we're already
      # displaying a dirty channel
      return if $scope.selectedChannel.dirtyIndicator
      list.size = list.length
      $log.log 'open room list'
      return



  return null


MessagesCtrl.resolve =
  currentCustomer: (Customer)->
    Customer.getCurrentCustomer()
  

app.controller "MessagesCtrl", MessagesCtrl
