# coffeelint: disable=max_line_length
SurveyResponseCtrl = ($scope
  , $rootScope
  , $log
  , $state
  , $q
  , $timeout
  , $ionicScrollDelegate
  , GeoLoc
  , SurveyResponse
  , Config
  , Util
  , Customer
  , Messages
  , Mail
  , Maps
  , Mustache
  , Moment
  , LocalStorage
  , currentSurvey
  , currentUser
  , googleReady
)->

  addressNotAvailableMsg = 'unable to obtain an address from this position'

  map = Maps.map
  params = $state.params
  $scope.params = params
  $scope.showEditDetails = false

  isEdit = false
  isNewVisit = false
  isNewHome = false

  $scope.editDetails = ()->
    $scope.showEditDetails = !$scope.showEditDetails

  setState = ()->
    isNewVisit = params.id && (params.action == 'visit')
    isEdit = params.id && !isNewVisit
    isNewHome = !(isNewVisit || isEdit)
    $scope.isNewVisit = isNewVisit
    $scope.isNewHome = isNewHome
    $scope.isEdit = isEdit
    title = ''
    if isNewVisit then title = 'New Visit'
    if isEdit then title = 'Edit last Visit'
    if isNewHome then title = 'Create New Home'
    $scope.title = title


  setState()

  $scope.newVisit = ()->
    params.action == 'visit'
    setState()
    $scope.reset(true) # partial reset

  $scope.goToHome = ()->

    pos = $scope.response.position
    $state.go 'canvass.home'
    Maps.goToPosition pos
    Maps.setZoom 18

  $scope.footer =
    display: isEdit

  $scope.messages = Messages
  $scope.goMessages = ()->
    $state.go 'canvass.messages'

  if !currentSurvey
    throw new Error('invalid survey (null/undefined)')

  # handle update/upgrade
  if !currentSurvey.type
    currentSurvey.type = 'simple'

  #$log.log 'current survey', currentSurvey
  $scope.survey = currentSurvey

  defaultAttitude = parseInt(LocalStorage.attitude, 10)

  $scope.reset = (partialReset)->
    console.log('reset, partial?', partialReset)
    $scope.confirmEmailMsg = ''
    $scope.footer.display = isEdit
    $scope.saveNewDisabled = false
    return if !currentUser
    if !currentSurvey
      throw new Error('invalid survey (null/undefined)')

    if partialReset
      # subsequent visits to the same home
      # we're only going to reset some information about this house
      # related to the lead.
      # other info - address, position, etc remains intact
      # we're also going to save the history of the previous visits
      copy = angular.copy $scope.response
      history = copy.history || []
      delete copy.history
      delete copy.survey
      history.unshift copy

      address =
        address: copy.questions.lead.address
        addressDetail: copy.questions.lead.addressDetail

      other = copy.questions.lead.other
      position = copy.position

    $scope.onAdminForm = false
    $scope.response =
      canvasser : currentUser._id
      survey : currentSurvey
      questions:
        attitude: defaultAttitude
        objections: angular.copy currentSurvey.config.objections
        lead:
          types: angular.copy currentSurvey.config.leadTypes

    if partialReset
      $scope.response.questions.lead.address = address.address
      $scope.response.questions.lead.addressDetail = address.addressDetail
      $scope.response.questions.lead.other = other
      $scope.response.position = position
      # newly crafted history
      $scope.response.history = history

    if $scope.response.position
      $log.log 'got a position, about to return'
      return

    # 'new' home

    $scope.updatePosMessage = 'getting current location'
    center = map && map.getCenter()
    GeoLoc.getCurrentPosition(center).then( (pos)->

      $scope.response.position = pos
      $scope.response.canvasserPosition = pos

      $scope.updatePosMessage = 'getting address at your location'
      GeoLoc.getAddressFromPosition(pos)
      .then( (address)->
        $log.log 'address', address
        $scope.updatePosMessage = 'address found'
        $timeout ()->
          $scope.updatePosMessage = ''
        , 5000

        $scope.response.questions.lead.address = address.formattedAddress
        $scope.response.questions.lead.addressDetail = address.addressDetail
        $log.log 'processed address'

      ).catch( (error)->

        $log.error 'error getting an address for this position', error, pos

        $scope.updatePosMessage = error + '; using previous address'
        $timeout ()->
          $scope.updatePosMessage = ''
        , 5000

        prev = SurveyResponse.getPrev $scope.response

        if prev
          if !prev?.questions?.lead?.address

            address = addressNotAvailableMsg
            addressDetail = GeoLoc.emptyAddressDetail()

          else

            lead = prev?.questions?.lead
            address = angular.copy lead?.address
            if lead?.addressDetail
              addressDetail = angular.copy lead.addressDetail
            else
              addressDetail = GeoLoc.emptyAddressDetail()
        else
          address = ""
          addressDetail = GeoLoc.emptyAddressDetail()

        $scope.response.questions.lead.address = address
        $scope.response.questions.lead.addressDetail = addressDetail

      )

      $log.log 'returning null'
      return null

    ).catch( (err)->
      $log.error 'error getting location or address information', err
    )
    return

  $scope.saveNewDisabled = false

  getPrev = ()->
    return SurveyResponse.getPrev $scope.response

  $scope.prev = ()->
    prev = getPrev()
    if prev
      $state.go 'canvass.surveyResponseid', {id:prev._id || prev.tmpId}

  getNext = ()->
    return SurveyResponse.getNext $scope.response

  $scope.next = ()->
    next = getNext()
    if next
      $state.go 'canvass.surveyResponseid', {id:next._id || next.tmpId}

  $scope.exit = ()->
    $state.go 'canvass.home'

  $scope.estimates = ()->
    $state.go 'canvass.estimates', { home: $scope.response._id }

  $scope.saveExit = ()->
    $scope.save false, $scope.exit

  $scope.saveNext = ()->
    $scope.save false, $scope.next

  $scope.savePrev = ()->
    $scope.save false, $scope.prev

  $scope.save = (reset, cb)->
    if reset
      $scope.saveNewDisabled = true

    if !$scope.response.position
      $scope.addressMessage = "position information not obtained for this home"


    if $scope.response.questions.lead.address == addressNotAvailableMsg
      $scope.response.questions.lead.address = null
      $scope.response.questions.lead.addressDetail = GeoLoc.emptyAddressDetail()

    # ensure that we update the current canvasser
    # as the home may have been created in advance
    # use the rootScope currentUser as it may have been updated
    # with current position
    $scope.response.canvasser = $rootScope.currentUser._id

    console.log 'about to save response', $scope.response
    SurveyResponse.save($scope.response)
    .then (response)->

      $scope.progressMessage = ''
      if !reset # don't bother showing this for save&new
        $scope.updateMessage = 'saved!'
        window.setTimeout ()->
          $scope.updateMessage = ''
          $scope.$apply()
         9800

      else

        $scope.reset()

      $ionicScrollDelegate.scrollTop()

      return
    .then ()->
      updateTotals()
    .then ()->
      if cb
        cb()

  $scope.$on 'surveyResponseUpdated', (e,data)->
    $log.log 'updated response', data.home
    r = data.home
    if r._id == $scope.response._id
      # update emails
      $scope.response.questions.lead.emails = r.questions.lead.emails

      # only update certain fields that were updated on the server.
      # otherwise we trigger the changed/dirty events on the form
      $scope.response.dateUpdated = r.dateUpdated
      $scope.response.survey = r.survey

    # send emails regardless if I'm viewing the current survey
    # (handle case of save & new
    if r.questions.gotLead && r.survey.config.sendLeadConfirmEmails
      # only send email if one has not already been sent
      lead = r.questions.lead
      if !lead.emails || lead.emails.length == 0
        $scope.sendConfirmEmail r

  renderConfirmEmail = (r)->

    return '' if !r.questions?.gotLead
    if !r.questions.lead
      r.questions.lead = {}

    # confirmation email build
    r.survey.config.replyTo =
      r.survey.config.replyTo || r.survey.config.emailFrom

    template = r.survey.config.leadConfirmEmailTemplate
    dateFormat = r.survey.config.leadConfirmEmailDateFormat

    # copy to avoid polluting the date fields
    copy = angular.copy r
    copy.lead = copy.questions.lead

    # format dates here, because mustache doesn't have simple
    # date formatters, nor do other common js template libs.
    copy.dateAdded = Moment(copy.dateAdded).format(dateFormat)
    copy.dateUpdated = Moment(copy.dateUpdated).format(dateFormat)
    if copy.lead.appointmentDate
      copy.lead.appointmentDate = Moment(copy.lead.appointmentDate)
        .format(dateFormat)

    if copy.survey.type == 'simple'
      copy.lead.topics = Util.formatLeadType copy.lead.type
    else
      copy.lead.topics = Util.formatLeadType copy.lead.types
    options =
      pretty: true

    output = Mustache.render template, copy
    return output


  $scope.sendConfirmEmail = (r)->

    if !$scope.online
      $scope.confirmEmailMsg = "unable to send email while offline"
      $log.log $scope.confirmEmailMsg
      return

    if !r.questions.lead.email
      $scope.confirmEmailMsg = 'This lead doe not have an email address!'
      return

    $scope.confirmEmailMsg = 'Sending...'
    emailText = renderConfirmEmail r
    if !emailText
      $scope.confirmEmailMsg = 'unable to create the confirmation email.'
      return
    params =
      text: emailText
      subject: r.survey.config.leadConfirmEmailSubject
      from_email: r.survey.config.emailFrom
      to: [
        email: r.questions.lead.email
        type: 'to'
      ]
      tags: ['lead-confirmations']

    if r.survey.config.replyTo
      params.headers =
        'Reply-To': r.survey.config.replyTo

    if r.survey.config.nameFrom
      params.from_name = r.survey.config.nameFrom

    $log.log 'mail params', params
    Mail.send(params).then( (result)->
      #$log.log result
      $scope.confirmEmailMsg = 'email sent!'

      copy = angular.copy r
      copy.questions.lead.emails = copy.questions.lead.emails || []
      copy.questions.lead.emails.push
        result: result
        dateAdded: new Date()
        email: params

      # don't update the 'dateUpdated' field of the response.
      # rely on the dateAdded Field in the emails list
      # to track activity
      SurveyResponse.save(copy)
      .then( (result)->

        # problem - on the app, the result is not
        # the actual saved item. the items are just queued for
        # saving later.

        ###
        $log.log 'updated home/lead', result
        # update the source record with the new email info
        r.questions.lead.emails = copy.questions.lead.emails
        ###

      ).catch( (err)->

        $log.error 'error saving survey response', err
        $scope.confirmEmailMsg = "Error updating email history "

      )
      return

    ).catch (err)->
      $log.error 'error sending mail', err
      $scope.confirmEmailMsg = parseErrors err

  if $state.params.id
    #$scope.onAdminForm = true
    SurveyResponse.one($state.params.id)
    .then( (response)->
      $log.log 'response', response

      if !response.survey
        response.survey = currentSurvey

      if !response.attitude
        response.attitude = defaultAttitude
      if !response.canvasserPosition && response.position
        response.canvasserPosition = response.position

      if !response.questions.lead
        response.questions.lead = {}


      if !response.questions.lead.address && response.position
        # go get an address for this one.
        GeoLoc.getAddressFromPosition(response.position)
        .then (address)->
          response.questions.lead.address = address.formattedAddress
          response.questions.lead.addressDetail = address.addressDetail

          $timeout ()->
            $scope.$apply()

      lead = response.questions.lead

      if lead.address && ! lead.addressDetail

        $log.log 'no address detail in lead, parsing from formatted address now'
        lead.addressDetail = GeoLoc.parseAddressFromFormatted lead.address
        $log.log 'lead.addressDetail', lead.addressDetail

      if !response.questions.lead.types
        response.questions.lead.types = response.survey.config.leadTypes


      # could do this more efficiently but this is simple.
      index = SurveyResponse.myResponses.findIndex (r)->
        r._id == response._id

      $scope.first = index == 0

      count = SurveyResponse.myResponses.length
      $scope.last = index == (count - 1)

      $scope.index = index + 1
      $scope.count = count

      $scope.response = response
      $scope.collapseAddress = !!response.dateUpdated
    ).then( ()->
      # response loaded, check to see if this is a new visit
      $scope.newVisit() if $state.params.action == 'visit'

    ).catch (err)->
      $log.error 'error surveyResponse.one', err

  else
    # use the position we just got.
    $scope.reset()

  updateTotals = ()->
    $scope.total = SurveyResponse.total()
    $scope.leads = SurveyResponse.leads()
    $scope.presented = SurveyResponse.presented() - $scope.leads
    $scope.notAvailable = $scope.total - $scope.leads - $scope.presented

    checkSynced()

  checkSynced = ()->
    synced = true
    SurveyResponse.myResponses.forEach (r)->
      if r.queueStatus
        synced = false

    $scope.synced = synced

  updateTotals()

SurveyResponseCtrl.resolve =
  googleReady: (Util)->
    Util.waitForGoogle()
  currentSurvey: (Survey, $log)->
    try
      return Survey.getMySurvey()
    catch e
      $log.error 'error resolve currentSurvey', e
    return []
  currentUser: (Auth, $log)->
    try
      return Auth.currentUser()
    catch e
      $log.error 'error resolve currentUser', e
    return null

app.controller 'SurveyResponseCtrl', SurveyResponseCtrl
