CampaignsCtrl = ($scope
  , $state
  , $log
  , Survey
  , surveys
)->

  $log.log 'Campaignsctrl'

  $scope.surveys = surveys

  $scope.updateActive = (survey)->
    Survey.setCurrent survey
    $log.log 'survey', survey



CampaignsCtrl.resolve =
  online: (Online)->
    Online.confirmedState()
  surveys: (Survey, $log)->
    try
      return Survey.getMySurveys()
        .catch (e)->
          $log.error 'error getting my surveys'
          return []
    catch e
      $log.error 'error resolve surveys', e
    return []

app.controller 'CampaignsCtrl', CampaignsCtrl
