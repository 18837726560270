app.factory 'User', ($resource, Config) ->
  $resource "#{Config.serverUri}/api/users/:id",
    id: "@id"
  ,
    update:
      method: "PUT"
      params: {}

    get:
      method: "GET"
      params:
        id: "me"
    all:
      method: "get"
      params: {}

    one:
      method: "get"
      params: {}
