RoutesCtrl = ( $scope
  , $state
  , routes
)->

  $scope.routes = routes


RoutesCtrl.resolve =
  routes: (Route, $log)->
    try
      # explicitly not using caching
      return Route.getMyRoutes()
    catch e
      $log.error 'error resolve routes', e
    return []


app.controller 'RoutesCtrl', RoutesCtrl
