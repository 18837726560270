app.controller "AboutCtrl", ($scope
, $state
, $window
, $log
, Auth
, LocalStorage
, GeoLoc
)->
  
  $scope.lastUpdated = canvassApp.timestamp

  $scope.clear = ()->

    LocalStorage.clear()
    Auth.logout()
    $state.go 'canvass.login'
    
  $scope.reload = ()->
    $window.location.reload()

  $scope.updatePosition = ()->
    $log.log 'updating position'

    Auth.updateCurrentUserGPS()


  $scope.toggleDebug = ()->
    $log.debugMode = !$log.debugMode

  $scope.$log = $log

